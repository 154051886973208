import * as React from "react";

export default function SocialMedia(props) {
  return (
<svg width="400" height="300" viewBox="0 0 843 771" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="undraw_social_girl_562b 1">
<g class="socialball">
<path id="Vector" d="M786.949 457.466C803.809 414.963 810.688 369.158 807.058 323.578C803.427 277.997 789.384 233.858 766.01 194.56L288.663 260.654L746.114 165.319C718.282 129.085 682.486 99.7426 641.496 79.5621C600.505 59.3816 555.421 48.9046 509.733 48.9421C345.003 48.9421 211.464 182.481 211.464 347.211C211.467 360.825 212.395 374.424 214.24 387.912L786.949 457.466Z" fill="#F2F2F2"/>
<path id="Vector_2" d="M231.401 454.599C274.513 566.262 382.864 645.479 509.733 645.479C628.78 645.479 731.524 575.726 779.361 474.868L231.401 454.599Z" fill="#F2F2F2"/>
</g>
<path class="hairback" d="M357.458 188.915C333.263 148.034 285.397 146.129 285.397 146.129C285.397 146.129 238.753 140.164 208.832 202.426C180.943 260.46 142.453 316.492 202.635 330.077L213.506 296.243L220.238 332.596C228.807 333.213 237.403 333.36 245.988 333.036C310.439 330.956 371.819 333.645 369.843 310.518C367.216 279.772 380.737 228.251 357.458 188.915Z" fill="#2F2E41"/>
<g class="face">
<path id="Vector_3" opacity="0.1" d="M251.243 284.821C250.362 289.435 249.409 294.218 246.636 298.011C243.446 302.374 238.244 304.866 234.812 309.042C229.645 315.327 229.349 324.171 229.328 332.307C229.308 339.801 229.46 347.795 233.64 354.017C236.352 358.054 240.48 360.885 244.513 363.603C260.187 374.166 277.125 384.919 296.023 385.267C303.853 385.411 312.248 383.389 317.4 377.491C319.625 374.721 321.374 371.6 322.576 368.257C327.408 356.346 330.338 343.749 331.256 330.927C331.687 324.895 331.646 318.687 329.604 312.995C327.308 306.594 322.666 301.327 319.598 295.259C315.972 288.042 314.645 279.887 315.798 271.894C315.951 271.331 315.903 270.732 315.664 270.2C315.412 269.916 315.097 269.693 314.745 269.55C314.392 269.407 314.011 269.348 313.632 269.377L276.16 266.679C272.671 266.506 269.195 266.108 265.757 265.489C263.737 265.078 258.401 262.209 256.475 262.972C252.91 264.384 251.974 280.994 251.243 284.821Z" fill="black"/>
<path id="Vector_4" d="M287.078 288.676C317.44 288.676 342.053 264.062 342.053 233.701C342.053 203.339 317.44 178.726 287.078 178.726C256.716 178.726 232.103 203.339 232.103 233.701C232.103 264.062 256.716 288.676 287.078 288.676Z" fill="#A0616A"/>
<path id="Vector_5" d="M251.243 283.651C250.362 288.265 249.409 293.049 246.636 296.841C243.446 301.204 238.244 303.697 234.812 307.872C229.645 314.157 229.349 323.001 229.328 331.137C229.308 338.632 229.46 346.626 233.64 352.847C236.352 356.885 240.48 359.715 244.513 362.434C260.187 372.997 277.125 383.749 296.023 384.097C303.853 384.242 312.248 382.22 317.4 376.322C319.625 373.551 321.374 370.431 322.576 367.087C327.408 355.176 330.338 342.579 331.256 329.758C331.687 323.725 331.646 317.517 329.604 311.825C327.308 305.424 322.666 300.158 319.598 294.089C315.972 286.873 314.645 278.718 315.798 270.724C315.951 270.161 315.903 269.563 315.664 269.031C315.412 268.746 315.097 268.523 314.745 268.38C314.392 268.238 314.011 268.178 313.632 268.207L276.16 265.509C272.671 265.336 269.195 264.938 265.757 264.319C263.737 263.909 258.401 261.04 256.475 261.803C252.91 263.215 251.974 279.824 251.243 283.651Z" fill="#A0616A"/>
</g>
<g class="socialupper">
<path id="Vector_6" d="M258.954 356.831C261.819 359.826 264.855 362.791 268.61 364.547C272.345 366.293 276.552 366.728 280.673 366.844C290.583 367.123 301.326 365.307 308.219 358.182C317.106 348.999 316.813 334.186 323.778 323.472C324.34 322.501 325.106 321.663 326.023 321.017C327.191 320.381 328.512 320.078 329.841 320.14C338.638 320.057 346.301 325.763 353.217 331.2C356.315 333.636 359.49 336.159 361.445 339.582C362.812 342.25 363.748 345.118 364.219 348.079C367.651 364.832 368.763 381.962 369.866 399.028C370.475 408.454 371.085 417.889 371.039 427.335C370.89 458.455 363.657 489.054 356.464 519.332C355.537 523.233 354.269 527.594 350.721 529.462C349.14 530.173 347.441 530.588 345.71 530.686C325.108 533.105 304.463 535.528 283.722 535.847C275.628 535.972 267.537 535.776 259.45 535.259C258.058 535.241 256.677 535 255.361 534.546C253.575 533.689 252.039 532.385 250.903 530.761C239.684 516.623 239.022 497.078 239.094 479.03C239.269 434.903 240.846 390.812 243.823 346.756C244.069 343.12 244.123 338.704 241.503 335.843C246.221 343.396 252.783 350.381 258.954 356.831Z" fill="#D0CDE1"/>
<path id="Vector_7" d="M247.309 293.354C245.452 296.715 231.763 305.061 229.12 307.847C214.355 323.411 187.405 322.827 173.901 339.497C169.149 345.362 166.681 352.732 164.865 360.058C162.298 370.421 160.927 381.721 165.228 391.492C169.367 400.893 178.08 407.393 183.995 415.791C190.491 425.012 193.411 436.208 196.21 447.134C198.276 455.201 200.36 463.469 199.555 471.757C198.64 481.182 194.079 489.811 191.387 498.889C188.694 507.966 188.229 518.778 194.455 525.912C199.029 531.153 206.203 533.231 213.061 534.4C221.813 535.891 230.709 536.316 239.579 536.708C247.418 537.054 256.059 537.122 262.085 532.096C266.84 528.131 268.907 521.697 269.174 515.511C269.442 509.326 268.155 503.195 267.349 497.056C266.513 490.693 266.192 484.274 265.923 477.861C264.717 449.089 264.553 420.298 265.43 391.488C265.612 385.493 265.834 379.425 264.555 373.565C262.874 365.858 258.709 358.971 255.234 351.89C248.335 337.835 245.073 324.182 246.139 308.56C247.192 293.141 253.916 281.394 247.309 293.354Z" fill="#C0DB1E"/>
<path id="Vector_8" d="M324.508 294.524C327.336 297.431 336.946 298.389 340.544 300.261C343.848 301.98 347.505 302.879 351.085 303.911C364.208 307.696 376.69 313.425 388.117 320.907C390.491 322.296 392.59 324.109 394.31 326.256C396.322 329.056 397.053 332.55 397.735 335.93C399.497 344.656 401.267 353.487 400.871 362.38C400.451 371.82 397.608 380.963 394.787 389.981L384.246 423.679C376.379 448.832 368.461 474.232 366.247 500.494C365.023 515.048 365.567 529.698 367.869 544.122C368.101 545.572 368.295 547.234 367.337 548.347C366.643 549.034 365.729 549.454 364.756 549.535C359.669 550.331 354.746 547.737 350.137 545.441C336.049 538.431 321.014 533.511 305.507 530.838C311.567 522.081 316.242 512.443 319.369 502.263C321.145 495.799 322.543 489.237 323.557 482.611L328.46 454.252C330.258 443.853 332.059 433.427 332.667 422.892C333.037 418.942 332.807 414.959 331.984 411.078C331.045 407.334 329.178 403.903 327.506 400.425C319.425 383.616 315.633 364.433 318.813 346.056C320.204 338.014 322.908 329.96 321.781 321.877C320.697 314.104 321.823 308.064 319.829 300.372C311.641 268.791 319.187 289.055 324.508 294.524Z" fill="#C0DB1E"/>
<path id="Vector_9" d="M173.761 366.074C170.077 373.718 163.918 380.231 161.822 388.454C161.006 392.297 160.657 396.224 160.781 400.151C160.731 406.069 160.699 412.108 162.516 417.74C163.686 421.366 165.589 424.711 166.961 428.266C168.872 433.232 169.724 438.544 169.461 443.859C169.091 451.223 166.589 458.371 166.394 465.741C166.086 477.332 171.447 488.228 176.678 498.576C177.995 495.315 180.008 492.382 182.577 489.981C185.146 487.579 188.208 485.768 191.55 484.674C194.892 483.58 198.432 483.23 201.924 483.647C205.415 484.064 208.774 485.239 211.764 487.09C213.144 485.815 213.291 483.722 213.345 481.845C213.732 468.398 214.105 454.95 214.464 441.502C215.03 420.705 215.57 399.629 210.785 379.381C208.625 370.239 205.264 361.132 199.102 354.041C195.858 350.308 184.844 340.473 179.518 344.656C177.123 346.537 177.683 351.657 177.303 354.25C176.73 358.348 175.536 362.335 173.761 366.074Z" fill="#C0DB1E"/>
<path id="Vector_10" d="M401.948 344.043C403.691 349.469 403.795 355.267 403.83 360.965C403.9 372.59 403.746 384.213 403.367 395.834C403.173 401.784 402.906 407.815 401.102 413.488C399.913 417.229 398.077 420.737 396.848 424.466C393.166 435.64 395.178 447.759 395.493 459.52C395.716 463.326 395.392 467.145 394.529 470.859C392.586 478.15 387.363 484.041 383.719 490.647C380.257 496.922 378.168 503.955 374.034 509.808C369.9 515.661 362.743 520.299 355.806 518.502C350.55 517.141 346.815 512.488 344.231 507.713C338.594 497.212 336.666 485.117 338.76 473.385C341.353 459.11 349.738 446.447 352.762 432.258C356.914 412.773 350.603 392.666 350.791 372.744C350.705 369.621 351.129 366.505 352.045 363.518C353.631 358.968 357.038 355.334 360.185 351.684C364.117 347.124 367.77 342.329 371.124 337.327C373.377 333.967 376.437 325.326 380.431 324.607C389.349 323.003 399.736 337.153 401.948 344.043Z" fill="#C0DB1E"/>
</g>
<g class="sociallower">
<path id="Vector_11" d="M199.199 546.929C198.302 551.391 197.638 555.927 196.113 560.215C194.599 564.472 192.261 568.409 190.877 572.71C186.465 586.421 192.582 601.707 202.352 612.29C210.824 621.43 221.76 627.924 233.841 630.988C242.655 633.205 251.819 633.524 260.902 633.832C286.053 634.685 311.851 635.448 335.684 627.365C343.213 624.748 350.486 621.444 357.41 617.495C359.596 616.453 361.522 614.937 363.048 613.056C364.865 610.468 365.098 607.112 365.082 603.949C365.029 593.287 362.867 582.679 363.38 572.029C363.665 566.13 364.769 560.236 364.225 554.355C362.993 541.018 352.402 529.08 339.306 526.268C332.546 524.816 325.533 525.607 318.65 526.269C300.784 527.986 282.841 528.788 264.892 528.673C246.554 528.556 228.328 525.757 210.08 525.464C204.684 525.378 204.793 527.041 203.075 532.264C201.493 537.071 200.198 541.968 199.199 546.929V546.929Z" fill="#2F2E41"/>
<path id="Vector_12" d="M127.488 538.263C122.556 537.024 117.475 536.484 112.393 536.659C107.054 536.954 101.838 538.382 97.0939 540.849C92.3494 543.316 88.1843 546.764 84.8757 550.965C81.5672 555.166 79.1909 560.024 77.9049 565.214C76.6189 570.405 76.4526 575.81 77.4172 581.07C78.4671 586.795 80.8257 592.193 83.3984 597.414C87.4014 605.538 92.2026 613.643 99.6133 618.849C103.948 621.894 108.966 623.793 113.923 625.66L152.954 640.364C158.696 642.527 164.439 644.69 170.237 646.696C199.369 656.765 229.742 662.803 260.51 664.642C269.882 665.2 279.396 665.356 288.539 663.222C290.708 662.716 293.023 661.961 294.279 660.121C295.023 658.825 295.461 657.376 295.561 655.885L297.963 639.071C298.301 636.703 298.627 634.211 297.759 631.983C296.659 629.157 293.901 627.38 291.271 625.869C265.612 611.132 235.651 603.001 214.052 582.773C208.968 578.012 204.713 570.464 198.535 567.229C191.529 563.561 183.279 561.467 176.038 558.087C160.208 550.698 144.644 542.207 127.488 538.263Z" fill="#2F2E41"/>
<path id="Vector_13" opacity="0.1" d="M166.454 560.931C180.25 565.253 238.495 595.202 248.479 605.659C247.141 606.141 201.737 580.696 200.375 580.287C187.991 576.571 175.538 572.829 163.777 567.39C161.411 566.296 148.407 561.153 148.996 558.168C149.661 554.799 164.005 560.163 166.454 560.931Z" fill="black"/>
<path id="Vector_14" d="M338.564 673.362C339.347 673.42 340.124 673.191 340.751 672.719C341.026 672.366 341.228 671.961 341.346 671.529C341.463 671.097 341.493 670.646 341.435 670.202C341.413 662.567 341.392 654.931 341.371 647.296C334.784 644.158 327.416 643.152 320.184 642.185L286.44 637.674C287.215 637.778 281.164 652.208 282.092 654.135C283.727 657.53 296.225 660.539 299.752 662.142C312.013 667.716 324.754 673.843 338.564 673.362Z" fill="#A0616A"/>
<path id="Vector_15" d="M232.694 647.217C226.323 648.231 219.212 649.128 215.086 654.088C209.853 660.377 212.081 669.753 214.577 677.544C214.948 679.12 215.692 680.584 216.747 681.812C218.451 683.493 221.061 683.739 223.449 683.89C229.259 684.258 235.443 684.546 240.519 681.696C242.924 680.347 244.91 678.374 247.151 676.767C250.894 674.276 255.009 672.395 259.343 671.193C267.309 668.688 275.508 666.998 283.816 666.15C287.072 665.818 290.394 665.604 293.457 664.45C296.52 663.297 299.357 661.009 300.247 657.858C301.423 653.696 299.003 649.452 296.683 645.801C293.792 641.253 290.73 636.465 286.42 633.117C280.133 628.233 277.023 631.982 270.689 635.108C258.702 641.024 245.894 645.106 232.694 647.217V647.217Z" fill="#A0616A"/>
<path id="Vector_16" d="M216.044 652.955L175.59 640.999C170.67 639.544 165.666 638.077 160.536 638.081C155.405 638.085 150.037 639.767 146.71 643.672C143.573 647.354 142.675 652.386 141.893 657.159L140.22 667.37C139.37 672.559 138.519 677.847 139.284 683.05C140.049 688.252 142.705 693.449 147.32 695.967C152.919 699.021 159.78 697.53 166.027 696.252C178.647 693.673 191.484 692.296 204.364 692.141C209.206 692.083 214.285 692.144 218.564 689.876C223.65 687.181 226.692 681.481 227.216 675.749C228.012 667.026 225.104 655.633 216.044 652.955Z" fill="#2F2E41"/>
<path id="Vector_17" d="M354.466 642.536C359.71 641.617 364.339 638.636 369.364 636.877C376.437 634.4 384.102 634.4 391.596 634.428C394.347 634.438 397.277 634.5 399.567 636.024C402.868 638.222 403.714 642.62 404.275 646.545C405.404 654.441 406.533 662.336 407.662 670.231C408.299 674.686 408.932 679.26 407.938 683.65C406.945 688.039 403.953 692.27 399.598 693.403C394.308 694.778 389.122 691.399 384.127 689.179C371.643 683.631 357.131 685.049 344.115 680.902C341.699 680.132 339.112 678.955 338.126 676.62C337.681 675.304 337.522 673.909 337.661 672.527C337.883 664.913 336.278 654.517 338.57 647.241C340.631 640.698 348.612 642.536 354.466 642.536Z" fill="#2F2E41"/>
<path id="Vector_18" d="M440.076 528.73C448.159 526.703 456.539 526.13 464.823 527.039C471.439 527.768 478.255 529.609 483.085 534.188C488.964 539.761 490.853 548.381 491.011 556.48C491.269 572.613 485.919 588.334 475.873 600.96C473.42 604.153 470.503 606.961 467.219 609.291C463.676 611.508 459.899 613.327 455.958 614.718L368.551 649.194C345.556 658.264 322.48 667.36 298.495 673.339C297.839 673.656 297.126 673.838 296.399 673.875C295.672 673.912 294.944 673.803 294.26 673.555C293.575 673.307 292.947 672.924 292.413 672.43C291.878 671.935 291.448 671.339 291.148 670.676C283.489 659.736 277.065 647.984 271.99 635.632C271.75 635.338 271.584 634.991 271.506 634.62C271.429 634.249 271.442 633.864 271.544 633.499C271.646 633.134 271.835 632.798 272.095 632.522C272.354 632.245 272.676 632.035 273.034 631.909L334.858 596.386C339.287 593.842 343.764 591.261 347.442 587.716C350.113 585.127 352.301 582.081 353.902 578.722C355.184 576.045 355.303 569.729 356.94 567.75C358.543 565.813 364.025 565.659 366.557 564.897C370.128 563.803 373.626 562.481 377.027 560.939C390.857 554.752 403.172 545.763 416.429 538.518C423.865 534.267 431.811 530.978 440.076 528.73Z" fill="#2F2E41"/>
<path id="Vector_19" opacity="0.1" d="M405.027 555.258C394.406 563.235 380.653 570.568 370.125 578.756C366.533 581.551 306.64 615.223 305.793 619.695C315.614 621.35 378.176 577.583 387.127 573.216C396.078 568.848 404.013 562.69 411.876 556.576C413.88 555.018 422.372 550.335 416.619 548.595C413.379 547.614 407.419 553.663 405.027 555.258Z" fill="black"/>
</g>
<path class="hairfront" d="M346.761 184.974L299.471 160.203L234.166 170.337L220.655 230.012L254.289 228.718L263.685 206.794V228.357L279.204 227.76L288.212 192.856L293.841 230.012L349.013 228.886L346.761 184.974Z" fill="#2F2E41"/>
<g class="socialtab">
<path id="Vector_20" d="M331.943 295.297H238.705C234.917 295.297 231.284 296.801 228.605 299.48C225.927 302.158 224.422 305.791 224.422 309.579V314.81H223.409V326.506H224.422V334.694H223.409V341.712H224.422V428.018C224.422 431.806 225.927 435.439 228.605 438.117C231.284 440.796 234.917 442.301 238.705 442.301H331.943C335.731 442.301 339.363 440.796 342.042 438.117C344.72 435.439 346.225 431.806 346.225 428.018V309.579C346.225 305.791 344.72 302.158 342.042 299.48C339.363 296.801 335.731 295.297 331.943 295.297V295.297Z" fill="#3F3D56"/>
<path id="Vector_21" d="M286.572 358.088C293.032 358.088 298.268 352.851 298.268 346.391C298.268 339.931 293.032 334.694 286.572 334.694C280.112 334.694 274.875 339.931 274.875 346.391C274.875 352.851 280.112 358.088 286.572 358.088Z" fill="#D0CDE1"/>
</g>
<path class="twitter" d="M780.321 358.914C767.927 358.914 755.812 362.589 745.507 369.475C735.202 376.36 727.17 386.147 722.428 397.597C717.685 409.047 716.444 421.647 718.862 433.802C721.28 445.958 727.248 457.123 736.011 465.887C744.775 474.651 755.94 480.619 768.096 483.037C780.252 485.455 792.851 484.214 804.301 479.471C815.752 474.728 825.538 466.696 832.424 456.391C839.309 446.086 842.984 433.971 842.984 421.577C842.984 413.348 841.364 405.2 838.215 397.597C835.065 389.994 830.45 383.086 824.631 377.268C818.812 371.449 811.904 366.833 804.301 363.684C796.699 360.535 788.55 358.914 780.321 358.914ZM811.29 405.684C811.323 406.361 811.34 407.054 811.34 407.747C811.34 428.888 795.265 453.264 765.839 453.264C757.157 453.283 748.658 450.771 741.381 446.036C742.646 446.188 743.919 446.265 745.193 446.267C752.393 446.279 759.387 443.867 765.047 439.418C761.71 439.355 758.476 438.25 755.798 436.259C753.12 434.267 751.132 431.488 750.111 428.311C751.1 428.51 752.106 428.61 753.115 428.608C754.536 428.608 755.952 428.419 757.323 428.047C753.705 427.315 750.451 425.354 748.113 422.496C745.776 419.639 744.499 416.06 744.5 412.368V412.17C746.721 413.419 749.214 414.104 751.762 414.167C748.372 411.908 745.971 408.441 745.048 404.473C744.125 400.505 744.75 396.335 746.794 392.812C750.801 397.757 755.806 401.802 761.482 404.683C767.158 407.563 773.378 409.215 779.735 409.53C779.455 408.334 779.317 407.11 779.323 405.882C779.319 402.676 780.281 399.542 782.082 396.889C783.884 394.236 786.443 392.187 789.425 391.009C792.407 389.83 795.675 389.576 798.804 390.28C801.932 390.985 804.776 392.614 806.966 394.957C810.547 394.257 813.981 392.945 817.116 391.079C815.923 394.783 813.425 397.927 810.085 399.925C813.252 399.541 816.344 398.69 819.261 397.4C817.117 400.613 814.418 403.418 811.29 405.684Z" fill="#15BCF1"/>
<g class="insta">
<path id="Vector_22" d="M760.172 195.751C766.321 195.751 771.307 190.766 771.307 184.616C771.307 178.467 766.321 173.481 760.172 173.481C754.022 173.481 749.037 178.467 749.037 184.616C749.037 190.766 754.022 195.751 760.172 195.751Z" fill="#650E38"/>
<path id="Vector_23" d="M774.091 156.779H746.253C742.565 156.79 739.031 158.26 736.423 160.868C733.815 163.476 732.345 167.01 732.335 170.698V198.535C732.345 202.223 733.815 205.757 736.423 208.365C739.031 210.973 742.565 212.443 746.253 212.454H774.091C777.779 212.443 781.313 210.973 783.921 208.365C786.528 205.757 787.998 202.223 788.009 198.535V170.698C787.998 167.01 786.528 163.476 783.921 160.868C781.313 158.26 777.779 156.79 774.091 156.779H774.091ZM760.172 201.319C756.868 201.319 753.639 200.339 750.893 198.504C748.146 196.669 746.005 194.06 744.741 191.008C743.477 187.956 743.146 184.598 743.79 181.358C744.435 178.118 746.026 175.142 748.362 172.806C750.697 170.47 753.674 168.879 756.913 168.235C760.153 167.59 763.512 167.921 766.564 169.185C769.616 170.45 772.224 172.59 774.059 175.337C775.895 178.084 776.874 181.313 776.874 184.616C776.873 189.046 775.113 193.293 771.981 196.425C768.849 199.558 764.601 201.318 760.172 201.319V201.319ZM778.266 170.698C777.44 170.698 776.633 170.453 775.946 169.994C775.26 169.535 774.724 168.883 774.408 168.12C774.092 167.357 774.01 166.517 774.171 165.707C774.332 164.897 774.73 164.153 775.313 163.57C775.897 162.986 776.641 162.588 777.451 162.427C778.261 162.266 779.101 162.348 779.864 162.664C780.627 162.98 781.279 163.516 781.738 164.202C782.197 164.889 782.442 165.696 782.442 166.522C782.443 167.071 782.336 167.614 782.127 168.122C781.917 168.629 781.61 169.09 781.222 169.478C780.834 169.866 780.373 170.173 779.866 170.383C779.358 170.592 778.815 170.699 778.266 170.698V170.698Z" fill="#A62060"/>
<path id="Vector_24" d="M759.457 119.141C746.392 119.141 733.62 123.015 722.756 130.274C711.892 137.533 703.425 147.85 698.425 159.921C693.425 171.992 692.117 185.274 694.666 198.089C697.215 210.903 703.507 222.674 712.745 231.913C721.984 241.152 733.755 247.443 746.569 249.992C759.384 252.541 772.667 251.233 784.738 246.233C796.809 241.233 807.126 232.766 814.385 221.902C821.643 211.039 825.518 198.267 825.518 185.201C825.518 176.526 823.809 167.936 820.489 159.921C817.169 151.906 812.303 144.624 806.169 138.489C800.035 132.355 792.752 127.489 784.738 124.169C776.723 120.849 768.132 119.141 759.457 119.141V119.141ZM793.577 198.535C793.561 203.698 791.504 208.646 787.853 212.297C784.201 215.948 779.254 218.006 774.091 218.021H746.253C741.09 218.006 736.142 215.948 732.491 212.297C728.84 208.646 726.782 203.698 726.767 198.535V170.698C726.782 165.534 728.84 160.587 732.491 156.936C736.142 153.285 741.09 151.227 746.253 151.212H774.091C779.254 151.227 784.202 153.285 787.853 156.936C791.504 160.587 793.562 165.534 793.577 170.698L793.577 198.535Z" fill="#BF0A61"/>
</g>
<path class="facebook" d="M491.492 0C478.636 0 466.069 3.81218 455.38 10.9545C444.691 18.0968 436.36 28.2484 431.44 40.1256C426.52 52.0028 425.233 65.0721 427.741 77.6809C430.249 90.2896 436.44 101.872 445.53 110.962C454.621 120.052 466.203 126.243 478.811 128.751C491.42 131.259 504.49 129.972 516.367 125.052C528.244 120.132 538.395 111.801 545.538 101.112C552.68 90.4229 556.492 77.8558 556.492 65C556.492 56.4641 554.811 48.0117 551.544 40.1256C548.278 32.2394 543.49 25.0739 537.454 19.0381C531.418 13.0022 524.253 8.21439 516.367 4.94783C508.481 1.68127 500.028 0 491.492 0ZM511.987 39.3308H503.667C497.986 39.3308 496.902 42.018 496.902 45.9779V54.3929H510.432L508.664 67.5928H496.902V103.421H481.816V67.5928H470.997V54.3929H481.816V44.6344C481.816 32.943 489.925 26.5788 500.367 26.5788C505.364 26.5788 510.738 26.9559 511.988 27.1209L511.987 39.3308Z" fill="#1D57ED"/>
<path class="lefthand" d="M186.133 521C188.47 524.488 191.207 527.9 194.96 529.782C199.65 532.133 205.438 531.714 210.134 529.375C214.79 526.879 218.601 523.058 221.085 518.396C226.225 509.32 227.289 498.461 226.648 488.051C226.006 477.641 223.783 467.39 222.8 457.007C222.085 449.459 222.026 441.866 221.969 434.284C221.957 432.691 221.982 430.975 222.924 429.689C224.062 428.135 226.146 427.706 227.993 427.158C238.366 424.076 245.251 414.353 250.187 404.724C253.483 398.294 256.349 391.266 255.714 384.068C255.078 376.871 249.905 369.62 242.717 368.886C236.791 368.281 231.319 372.045 227.044 376.193C216.261 386.724 209.331 400.574 207.367 415.518C206.429 422.836 206.698 430.353 204.707 437.458C200.457 452.627 187.16 461.927 180.533 475.577C173.231 490.618 177.261 507.693 186.133 521Z" fill="#A0616A"/>
<path class="righthand" d="M381.813 463.047C380.301 466.566 378.099 469.745 375.338 472.398C372.577 475.051 369.312 477.124 365.736 478.495C361.292 480.28 356.389 480.58 351.761 479.351C349.465 478.714 347.347 477.557 345.571 475.968C343.796 474.38 342.41 472.404 341.522 470.193C340.814 467.967 340.426 465.651 340.369 463.315C339.704 453.085 339.961 442.544 343.677 432.99C345.11 429.305 347.042 425.81 348.173 422.022C349.464 417.701 349.67 413.141 349.867 408.636L351.088 380.725C351.29 378.698 351.15 376.652 350.674 374.673C349.455 370.634 345.681 368.009 342.217 365.601C333.853 359.784 325.588 352.997 321.535 343.65C320.295 340.79 319.477 337.611 320.106 334.558C321.249 329.016 326.842 325.428 332.371 324.219C335.988 323.428 339.728 323.377 343.365 324.07C347.002 324.763 350.462 326.185 353.535 328.251C356.608 330.317 359.231 332.983 361.246 336.089C363.261 339.195 364.626 342.678 365.26 346.326C365.807 349.476 365.799 352.693 366.119 355.874C366.798 362.621 368.94 369.126 370.28 375.773C373.581 392.149 371.871 409.076 375.135 425.448C377.864 439.137 388.155 448.069 381.813 463.047Z" fill="#A0616A"/>
</g>
</svg>

  );
}
