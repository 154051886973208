import * as React from "react";

export default function Webdev(props) {
  return (
<svg width="400" height="276" viewBox="0 0 917 763" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="mobilescreen">
    <path id="Vector" d="M223.09 198H93.77C88.2471 198 83.77 202.477 83.77 208V459.94C83.77 465.463 88.2471 469.94 93.77 469.94H223.09C228.613 469.94 233.09 465.463 233.09 459.94V208C233.09 202.477 228.613 198 223.09 198Z" fill="url(#paint0_linear)"/>
    <path id="Vector_2" d="M219.65 202.47H97.21C91.6871 202.47 87.21 206.947 87.21 212.47V453.62C87.21 459.143 91.6871 463.62 97.21 463.62H219.65C225.173 463.62 229.65 459.143 229.65 453.62V212.47C229.65 206.947 225.173 202.47 219.65 202.47Z" fill="white"/>
    <path id="Vector_3" d="M213.974 220.443L103.694 220.597L103.991 433.117L214.271 432.963L213.974 220.443Z" fill="#E6E6E6"/>
    <path id="Vector_4" d="M159.155 457.359C164.009 457.353 167.939 453.313 167.932 448.337C167.925 443.361 163.984 439.333 159.129 439.339C154.275 439.346 150.345 443.386 150.352 448.362C150.359 453.338 154.3 457.366 159.155 457.359Z" fill="#DBDBDB"/>
    <path id="Vector_5" d="M142.123 212.423C143.095 212.422 143.882 211.615 143.88 210.621C143.879 209.627 143.09 208.822 142.118 208.823C141.146 208.825 140.359 209.632 140.36 210.626C140.362 211.62 141.151 212.425 142.123 212.423Z" fill="#DBDBDB"/>
    <path id="Vector_6" d="M176.288 209.226L151.028 209.261C150.476 209.262 150.029 209.71 150.03 210.262L150.031 210.962C150.031 211.515 150.48 211.962 151.032 211.961L176.292 211.926C176.844 211.925 177.291 211.477 177.291 210.924L177.29 210.224C177.289 209.672 176.84 209.225 176.288 209.226Z" fill="#DBDBDB"/>
    </g>
   
    <g class="mobileman">
    <path id="Vector_7" d="M190.2 343.76H127.76V409.32H190.2V343.76Z" fill="url(#paint1_linear)"/>
    <path id="Vector_8" d="M187.49 346.61H130.48V406.47H187.49V346.61Z" fill="#6C63FF"/>
    <g id="Group" opacity="0.5">
    <path id="Vector_9" opacity="0.5" d="M163.3 373.74V372.61H166.3V366.82C167.209 365.44 167.732 363.843 167.816 362.193C167.9 360.543 167.542 358.901 166.778 357.436C166.015 355.971 164.874 354.737 163.473 353.861C162.072 352.985 160.463 352.5 158.811 352.454C157.16 352.409 155.526 352.806 154.08 353.605C152.634 354.403 151.427 355.573 150.584 356.994C149.742 358.415 149.294 360.035 149.288 361.687C149.282 363.339 149.718 364.963 150.55 366.39V372.61H153.86V373.94C149.806 375.058 146.23 377.475 143.682 380.82C141.133 384.166 139.752 388.254 139.75 392.46V409.32H178.24V392.46C178.238 388.109 176.759 383.887 174.045 380.487C171.33 377.086 167.542 374.707 163.3 373.74V373.74Z" fill="url(#paint2_linear)"/>
    </g>
    <path id="Vector_10" d="M165.63 362H151.38V373.4H165.63V362Z" fill="#F55F44"/>
    <path id="Vector_11" d="M154.46 374H163.46C166.871 374 170.142 375.355 172.553 377.767C174.965 380.178 176.32 383.449 176.32 386.86V406.64H141.6V386.84C141.605 383.433 142.963 380.167 145.374 377.76C147.785 375.352 151.053 374 154.46 374V374Z" fill="#FDD835"/>
    <path id="Vector_12" d="M159.73 368.3H157.52C155.78 368.3 154.37 369.71 154.37 371.45V375.79C154.37 377.53 155.78 378.94 157.52 378.94H159.73C161.47 378.94 162.88 377.53 162.88 375.79V371.45C162.88 369.71 161.47 368.3 159.73 368.3Z" fill="#FDA57D"/>
    <path id="Vector_13" d="M157 378.62L158.59 380.94L160.26 378.62H157Z" fill="#FDA57D"/>
    <path id="Vector_14" opacity="0.1" d="M148.14 387C148.14 387 146.45 403.82 147.54 404.91Z" fill="black"/>
    <path id="Vector_15" opacity="0.05" d="M158.63 373.47C160.142 373.472 161.614 372.988 162.83 372.09C162.671 371.09 162.161 370.18 161.391 369.522C160.621 368.864 159.642 368.502 158.63 368.5C157.618 368.502 156.639 368.864 155.869 369.522C155.099 370.18 154.589 371.09 154.43 372.09C155.646 372.988 157.118 373.472 158.63 373.47Z" fill="black"/>
    <path id="Vector_16" d="M158.63 371.94C163.264 371.94 167.02 368.184 167.02 363.55C167.02 358.916 163.264 355.16 158.63 355.16C153.996 355.16 150.24 358.916 150.24 363.55C150.24 368.184 153.996 371.94 158.63 371.94Z" fill="#F55F44"/>
    <path id="Vector_17" d="M158.63 373.26C162.546 373.26 165.72 370.086 165.72 366.17C165.72 362.254 162.546 359.08 158.63 359.08C154.714 359.08 151.54 362.254 151.54 366.17C151.54 370.086 154.714 373.26 158.63 373.26Z" fill="#FDA57D"/>
    <path id="Vector_18" d="M151.52 366.61C151.796 366.61 152.02 366.207 152.02 365.71C152.02 365.213 151.796 364.81 151.52 364.81C151.244 364.81 151.02 365.213 151.02 365.71C151.02 366.207 151.244 366.61 151.52 366.61Z" fill="#FDA57D"/>
    <path id="Vector_19" d="M165.75 366.61C166.026 366.61 166.25 366.207 166.25 365.71C166.25 365.213 166.026 364.81 165.75 364.81C165.474 364.81 165.25 365.213 165.25 365.71C165.25 366.207 165.474 366.61 165.75 366.61Z" fill="#FDA57D"/>
    </g>
    <g class="mobilelines">
    <path id="Vector_20" d="M207.1 234.26H110.86V238.44H207.1V234.26Z" fill="white"/>
    <path id="Vector_21" d="M207.1 242.62H110.86V246.8H207.1V242.62Z" fill="white"/>
    <path id="Vector_22" d="M165.26 250.99H110.86V255.17H165.26V250.99Z" fill="white"/>
    <path id="Vector_23" d="M190.36 259.36H110.86V263.54H190.36V259.36Z" fill="white"/>
    <path id="Vector_24" d="M177.81 267.73H110.86V271.91H177.81V267.73Z" fill="white"/>
    <path id="Vector_25" d="M207.1 276.1H110.86V280.28H207.1V276.1Z" fill="white"/>
    </g>
    <path class="mobileparagraph" d="M198.74 288.65H115.05V322.12H198.74V288.65Z" fill="white"/>

    <g class="desktopscreen">
    <path id="Vector_26" d="M705.47 457.13C705.47 457.13 719.47 553.32 801.66 585.27L635.36 585.5L469.06 585.73C551.12 553.55 564.91 457.32 564.91 457.32L705.47 457.13Z" fill="#E0E0E0"/>
    <path id="Vector_27" d="M803.143 585.25L467.403 585.719L467.432 606.039L803.171 605.57L803.143 585.25Z" fill="url(#paint3_linear)"/>
    <path id="Vector_28" d="M801.683 585.252L469.083 585.716L469.105 601.396L801.705 600.932L801.683 585.252Z" fill="#F5F5F5"/>
    <path id="Vector_29" d="M905.24 111L364.34 111.75C361.398 111.821 358.604 113.057 356.573 115.188C354.542 117.318 353.44 120.168 353.51 123.11L353.93 438.69V449.2C353.93 473.2 372.56 492.59 395.48 492.56L875.09 491.9C898 491.9 916.55 472.41 916.52 448.43V437.93L916.1 122.35C916.133 120.891 915.878 119.441 915.35 118.081C914.823 116.72 914.032 115.478 913.023 114.424C912.014 113.369 910.808 112.524 909.472 111.937C908.137 111.35 906.699 111.031 905.24 111V111Z" fill="url(#paint4_linear)"/>
    <path id="Vector_30" d="M370.76 121.57H898.25C901.101 121.57 903.835 122.703 905.851 124.719C907.867 126.735 909 129.469 909 132.32V432.64H360V132.32C360 130.907 360.278 129.509 360.819 128.204C361.36 126.899 362.153 125.713 363.152 124.715C364.151 123.717 365.338 122.925 366.643 122.385C367.949 121.846 369.347 121.569 370.76 121.57V121.57Z" fill="white"/>
    <path id="Vector_31" d="M871.41 482.56L399.05 483.21C394.018 483.217 389.033 482.232 384.382 480.312C379.73 478.393 375.502 475.575 371.939 472.022C368.376 468.468 365.548 464.247 363.616 459.601C361.684 454.954 360.687 449.972 360.68 444.94V431.94L909.68 431.18V444.18C909.688 449.213 908.704 454.198 906.785 458.851C904.866 463.504 902.049 467.733 898.495 471.297C894.941 474.861 890.72 477.691 886.073 479.623C881.426 481.555 876.443 482.553 871.41 482.56V482.56Z" fill="#F5F5F5"/>
    <path id="Vector_32" d="M883.93 146.77H387.12V397.89H883.93V146.77Z" fill="#E6E6E6"/>
    </g>    
    <g class="desktopman">
    <path id="Vector_33" d="M841.16 163.33H753.22V255.67H841.16V163.33Z" fill="url(#paint5_linear)"/>
    <path id="Vector_34" d="M837.34 167.35H757.04V251.66H837.34V167.35Z" fill="#6C63FF"/>
    <g id="Group_2" opacity="0.5">
    <path id="Vector_35" opacity="0.5" d="M802.68 204.84L801.68 204.48L801.62 204.57H801.56V201.85C801.56 201.515 801.533 201.181 801.48 200.85C803.1 199.658 804.34 198.022 805.05 196.14C806.451 194.325 807.314 192.153 807.544 189.872C807.773 187.59 807.358 185.29 806.347 183.233C805.335 181.175 803.767 179.442 801.821 178.23C799.875 177.018 797.628 176.375 795.335 176.375C793.042 176.375 790.795 177.018 788.849 178.23C786.903 179.442 785.335 181.175 784.323 183.233C783.312 185.29 782.897 187.59 783.126 189.872C783.356 192.153 784.219 194.325 785.62 196.14C786.33 198.022 787.57 199.658 789.19 200.85C789.137 201.181 789.11 201.515 789.11 201.85V204.85C783.744 206.331 779.012 209.532 775.639 213.961C772.267 218.39 770.441 223.803 770.44 229.37V251.68H821.24V229.34C821.242 223.791 819.429 218.393 816.078 213.97C812.727 209.547 808.022 206.341 802.68 204.84V204.84Z" fill="url(#paint6_linear)"/>
    </g>
    <path id="Vector_36" d="M794.3 204.29H797.4C803.481 204.29 809.314 206.706 813.614 211.006C817.914 215.306 820.33 221.139 820.33 227.22V250.22H771.33V227.22C771.33 224.205 771.924 221.22 773.079 218.436C774.234 215.651 775.927 213.122 778.06 210.992C780.194 208.862 782.726 207.174 785.513 206.024C788.299 204.874 791.285 204.285 794.3 204.29V204.29Z" fill="#514ABF"/>
    <path id="Vector_37" d="M795.73 196.3H794.98C791.876 196.3 789.36 198.816 789.36 201.92V205.67C789.36 208.774 791.876 211.29 794.98 211.29H795.73C798.834 211.29 801.35 208.774 801.35 205.67V201.92C801.35 198.816 798.834 196.3 795.73 196.3Z" fill="#FDA57D"/>
    <path id="Vector_38" opacity="0.05" d="M785.2 206.94L789.22 215.56L793.21 211.57L789.22 205.44L785.2 206.94Z" fill="black"/>
    <path id="Vector_39" d="M785.34 206.52L789.34 215.14L793.34 211.14L789.34 205.02L787.5 205.58C787.319 205.636 787.142 205.703 786.97 205.78L785.34 206.52Z" fill="white"/>
    <path id="Vector_40" opacity="0.05" d="M805.52 206.52L801.49 215.56L797.49 211.57L801.6 205.12L805.52 206.52Z" fill="black"/>
    <path id="Vector_41" d="M805.38 206.1L801.38 215.1L797.38 211.1L801.49 204.65L802.24 204.87C803.054 205.102 803.852 205.386 804.63 205.72L805.38 206.1Z" fill="white"/>
    <path id="Vector_42" d="M793.05 210.83L795.29 214.09L797.65 210.83H793.05Z" fill="#FDA57D"/>
    <path id="Vector_43" opacity="0.1" d="M780.57 222.64C780.57 222.64 778.19 246.33 779.73 247.87Z" fill="black"/>
    <path id="Vector_44" opacity="0.1" d="M810.01 222.64C810.01 222.64 812.39 246.33 810.85 247.87Z" fill="black"/>
    <path id="Vector_45" opacity="0.05" d="M795.35 203.57C797.48 203.57 799.554 202.891 801.27 201.63C801.044 200.222 800.324 198.941 799.24 198.015C798.155 197.09 796.776 196.581 795.35 196.58V196.58C793.924 196.581 792.545 197.09 791.46 198.015C790.376 198.941 789.656 200.222 789.43 201.63C791.146 202.891 793.22 203.57 795.35 203.57V203.57Z" fill="black"/>
    <path id="Vector_46" d="M795.35 201.41C801.872 201.41 807.16 196.122 807.16 189.6C807.16 183.078 801.872 177.79 795.35 177.79C788.827 177.79 783.54 183.078 783.54 189.6C783.54 196.122 788.827 201.41 795.35 201.41Z" fill="black"/>
    <path id="Vector_47" d="M795.35 203.29C800.867 203.29 805.34 198.817 805.34 193.3C805.34 187.783 800.867 183.31 795.35 183.31C789.833 183.31 785.36 187.783 785.36 193.3C785.36 198.817 789.833 203.29 795.35 203.29Z" fill="#FDA57D"/>
    <path id="Vector_48" opacity="0.05" d="M787.3 183.4L789.82 186.48C789.82 186.48 800.89 187.48 802.15 184.94C803.41 182.4 794.86 180.59 794.86 180.59L787.3 183.4Z" fill="black"/>
    <path id="Vector_49" d="M787.3 183.12L789.82 186.2C789.82 186.2 800.89 187.2 802.15 184.66C803.41 182.12 794.86 180.31 794.86 180.31L787.3 183.12Z" fill="black"/>
    <path id="Vector_50" d="M785.34 193.91C785.727 193.91 786.04 193.346 786.04 192.65C786.04 191.954 785.727 191.39 785.34 191.39C784.953 191.39 784.64 191.954 784.64 192.65C784.64 193.346 784.953 193.91 785.34 193.91Z" fill="#FDA57D"/>
    <path id="Vector_51" d="M805.38 193.91C805.767 193.91 806.08 193.346 806.08 192.65C806.08 191.954 805.767 191.39 805.38 191.39C804.993 191.39 804.68 191.954 804.68 192.65C804.68 193.346 804.993 193.91 805.38 193.91Z" fill="#FDA57D"/>
    </g>
    <g class="desktopwoman">
    <path id="Vector_52" d="M841.67 274.1H753.73V366.44H841.67V274.1Z" fill="url(#paint7_linear)"/>
    <path id="Vector_53" d="M837.85 278.11H757.55V362.42H837.85V278.11Z" fill="#6C63FF"/>
    <path id="Vector_54" d="M796.15 316.66H799.25C802.261 316.66 805.243 317.253 808.025 318.405C810.807 319.558 813.335 321.247 815.464 323.376C817.593 325.505 819.282 328.033 820.435 330.815C821.587 333.597 822.18 336.579 822.18 339.59V362.59H773.18V339.59C773.18 333.509 775.596 327.676 779.896 323.376C784.196 319.076 790.029 316.66 796.11 316.66H796.15Z" fill="white"/>
    <path id="Vector_55" d="M797.57 308.67H796.82C793.716 308.67 791.2 311.186 791.2 314.29V318.04C791.2 321.144 793.716 323.66 796.82 323.66H797.57C800.674 323.66 803.19 321.144 803.19 318.04V314.29C803.19 311.186 800.674 308.67 797.57 308.67Z" fill="#FDB797"/>
    <path id="Vector_56" d="M794.9 323.2L797.14 326.46L799.49 323.2H794.9Z" fill="#FDB797"/>
    <path id="Vector_57" opacity="0.1" d="M782.42 335C782.42 335 780.04 358.69 781.58 360.23Z" fill="black"/>
    <path id="Vector_58" opacity="0.1" d="M811.86 335C811.86 335 814.24 358.69 812.7 360.23Z" fill="black"/>
    <path id="Vector_59" opacity="0.05" d="M797.24 315.94C799.37 315.94 801.444 315.261 803.16 314C802.934 312.592 802.214 311.311 801.13 310.385C800.045 309.46 798.666 308.951 797.24 308.95V308.95C795.8 308.932 794.402 309.432 793.301 310.359C792.199 311.286 791.468 312.578 791.24 314C792.978 315.277 795.083 315.957 797.24 315.94V315.94Z" fill="black"/>
    <path id="Vector_60" d="M797.2 313.78C803.722 313.78 809.01 308.492 809.01 301.97C809.01 295.448 803.722 290.16 797.2 290.16C790.678 290.16 785.39 295.448 785.39 301.97C785.39 308.492 790.678 313.78 797.2 313.78Z" fill="#FDD835"/>
    <path id="Vector_61" d="M797.2 315.66C802.717 315.66 807.19 311.187 807.19 305.67C807.19 300.153 802.717 295.68 797.2 295.68C791.683 295.68 787.21 300.153 787.21 305.67C787.21 311.187 791.683 315.66 797.2 315.66Z" fill="#FDB797"/>
    <path id="Vector_62" d="M787.18 306.28C787.567 306.28 787.88 305.716 787.88 305.02C787.88 304.324 787.567 303.76 787.18 303.76C786.793 303.76 786.48 304.324 786.48 305.02C786.48 305.716 786.793 306.28 787.18 306.28Z" fill="#FDB797"/>
    <path id="Vector_63" d="M807.23 306.28C807.617 306.28 807.93 305.716 807.93 305.02C807.93 304.324 807.617 303.76 807.23 303.76C806.843 303.76 806.53 304.324 806.53 305.02C806.53 305.716 806.843 306.28 807.23 306.28Z" fill="#FDB797"/>
    <path id="Vector_64" d="M797.7 294.17C799.915 294.17 801.71 292.375 801.71 290.16C801.71 287.945 799.915 286.15 797.7 286.15C795.485 286.15 793.69 287.945 793.69 290.16C793.69 292.375 795.485 294.17 797.7 294.17Z" fill="#FDD835"/>
    <path id="Vector_65" d="M789.67 294.17C789.67 294.17 793.67 302.17 805.73 298.17L801.73 294.17H789.67Z" fill="#FDD835"/>
    </g>
    <g class="desktoplines">
    <path id="Vector_66" d="M647.99 178.28H427.01V187.89H647.99V178.28Z" fill="white"/>
    <path id="Vector_67" d="M647.99 197.49H427.01V207.1H647.99V197.49Z" fill="white"/>
    <path id="Vector_68" d="M551.91 216.71H427.01V226.32H551.91V216.71Z" fill="white"/>
    <path id="Vector_69" d="M609.56 235.92H427.01V245.53H609.56V235.92Z" fill="white"/>
    <path id="Vector_70" d="M580.73 255.14H427.01V264.75H580.73V255.14Z" fill="white"/>
    <path id="Vector_71" d="M647.99 274.35H427.01V283.96H647.99V274.35Z" fill="white"/>
    </g>
    <path class="desktopparagraph" d="M628.77 303.18H436.62V380.04H628.77V303.18Z" fill="white"/>
    <path class="webline" d="M138.78 610.63H33.09V34.16H657.6V82.2" stroke="#3AD29F" stroke-width="6" stroke-miterlimit="10" stroke-dasharray="12 12"/>
    <g class="round-check">
    <g id="Group_3" opacity="0.5">
    <path id="Vector_72" opacity="0.5" d="M36.3 72.6C56.3479 72.6 72.6 56.3479 72.6 36.3C72.6 16.2521 56.3479 0 36.3 0C16.2521 0 0 16.2521 0 36.3C0 56.3479 16.2521 72.6 36.3 72.6Z" fill="url(#paint8_linear)"/>
    </g>
    <path id="Vector_73" d="M36.3 70.46C55.1661 70.46 70.46 55.1661 70.46 36.3C70.46 17.434 55.1661 2.14001 36.3 2.14001C17.434 2.14001 2.14001 17.434 2.14001 36.3C2.14001 55.1661 17.434 70.46 36.3 70.46Z" fill="#69F0AE"/>
    <path id="Vector_74" d="M23.49 34.16L36.3 45.9L54.44 19.21L60.85 24.55L36.3 56.58L18.15 36.3L23.49 34.16Z" fill="white"/>
    </g>
    <g class="tabletscreen">
    <path id="Vector_75" opacity="0.1" d="M169.04 387C169.04 387 170.73 403.82 169.64 404.91Z" fill="black"/>
    <path id="Vector_76" d="M424.29 332.029L181.47 332.368C171.987 332.381 164.311 340.079 164.324 349.562L164.876 744.861C164.889 754.344 172.587 762.021 182.07 762.008L424.89 761.668C434.372 761.655 442.049 753.957 442.036 744.474L441.484 349.175C441.47 339.692 433.772 332.016 424.29 332.029Z" fill="url(#paint9_linear)"/>
    <path id="Vector_77" d="M423.095 364.851L183.746 365.185L184.249 725.534L423.599 725.2L423.095 364.851Z" fill="url(#paint10_linear)"/>
    <path id="Vector_78" d="M420.359 338.524L185.419 338.852C178.416 338.862 172.747 344.547 172.757 351.55L173.294 736.04C173.303 743.043 178.988 748.712 185.991 748.702L420.931 748.374C427.934 748.364 433.603 742.679 433.593 735.676L433.056 351.187C433.047 344.184 427.362 338.515 420.359 338.524Z" fill="white"/>
    <path id="Vector_79" d="M415.792 369.851L191.003 370.165L191.483 713.924L416.272 713.61L415.792 369.851Z" fill="#E6E6E6"/>
    <path id="Vector_80" d="M303.39 740.95C308.504 740.95 312.65 736.701 312.65 731.46C312.65 726.219 308.504 721.97 303.39 721.97C298.276 721.97 294.13 726.219 294.13 731.46C294.13 736.701 298.276 740.95 303.39 740.95Z" fill="#DBDBDB"/>
    <path id="Vector_81" d="M264.486 358.352C266.193 358.35 267.574 356.933 267.572 355.188C267.569 353.443 266.184 352.03 264.477 352.032C262.771 352.035 261.389 353.451 261.392 355.197C261.394 356.942 262.78 358.355 264.486 358.352Z" fill="#DBDBDB"/>
    <path id="Vector_82" d="M319.299 353.216L280.679 353.27C280.259 353.27 279.92 353.611 279.92 354.031L279.923 355.671C279.923 356.091 280.264 356.43 280.684 356.43L319.304 356.376C319.723 356.375 320.063 356.034 320.062 355.615L320.06 353.975C320.06 353.555 319.719 353.215 319.299 353.216Z" fill="#DBDBDB"/>
    </g>
    <g class="tabletwoman">
    <path id="Vector_83" d="M390.22 581.8H302.28V674.14H390.22V581.8Z" fill="url(#paint11_linear)"/>
    <path id="Vector_84" d="M386.4 585.82H306.1V670.13H386.4V585.82Z" fill="#6C63FF"/>
    <g id="Group_4" opacity="0.5">
    <path id="Vector_85" opacity="0.5" d="M353.08 623.32L352.08 622.96L352.02 623.05H351.96V620.33C351.96 619.995 351.933 619.661 351.88 619.33C353.5 618.138 354.74 616.502 355.45 614.62C356.851 612.805 357.714 610.633 357.944 608.352C358.173 606.07 357.758 603.77 356.747 601.713C355.735 599.655 354.167 597.922 352.221 596.71C350.275 595.498 348.028 594.855 345.735 594.855C343.442 594.855 341.195 595.498 339.249 596.71C337.303 597.922 335.735 599.655 334.723 601.713C333.712 603.77 333.297 606.07 333.526 608.352C333.756 610.633 334.619 612.805 336.02 614.62C336.73 616.502 337.97 618.138 339.59 619.33C339.537 619.661 339.51 619.995 339.51 620.33V623.33C334.144 624.811 329.412 628.012 326.039 632.441C322.667 636.87 320.841 642.283 320.84 647.85V670.16H371.69V647.81C371.687 642.256 369.866 636.856 366.505 632.434C363.145 628.012 358.43 624.811 353.08 623.32V623.32Z" fill="url(#paint12_linear)"/>
    </g>
    <path id="Vector_86" d="M344.7 622.76H347.8C353.881 622.76 359.714 625.176 364.014 629.476C368.314 633.776 370.73 639.609 370.73 645.69V668.69H321.73V645.69C321.73 642.675 322.324 639.69 323.479 636.906C324.634 634.121 326.327 631.592 328.46 629.462C330.594 627.332 333.126 625.644 335.913 624.494C338.699 623.344 341.685 622.755 344.7 622.76V622.76Z" fill="#3AD29F"/>
    <path id="Vector_87" d="M346.13 614.77H345.38C342.276 614.77 339.76 617.286 339.76 620.39V624.14C339.76 627.244 342.276 629.76 345.38 629.76H346.13C349.234 629.76 351.75 627.244 351.75 624.14V620.39C351.75 617.286 349.234 614.77 346.13 614.77Z" fill="#BE7C5E"/>
    <path id="Vector_88" d="M343.45 629.3L345.69 632.56L348.05 629.3H343.45Z" fill="#BE7C5E"/>
    <path id="Vector_89" opacity="0.1" d="M330.97 641.11C330.97 641.11 328.59 664.8 330.13 666.34Z" fill="black"/>
    <path id="Vector_90" opacity="0.1" d="M360.41 641.11C360.41 641.11 362.79 664.8 361.25 666.34Z" fill="black"/>
    <path id="Vector_91" opacity="0.05" d="M345.75 622C347.88 622 349.954 621.321 351.67 620.06C351.444 618.652 350.724 617.371 349.64 616.445C348.555 615.52 347.176 615.011 345.75 615.01V615.01C344.324 615.011 342.945 615.52 341.86 616.445C340.776 617.371 340.056 618.652 339.83 620.06C341.546 621.321 343.62 622 345.75 622V622Z" fill="black"/>
    <path id="Vector_92" d="M345.75 619.88C352.272 619.88 357.56 614.592 357.56 608.07C357.56 601.548 352.272 596.26 345.75 596.26C339.228 596.26 333.94 601.548 333.94 608.07C333.94 614.592 339.228 619.88 345.75 619.88Z" fill="#444444"/>
    <path id="Vector_93" d="M345.75 621.76C351.267 621.76 355.74 617.287 355.74 611.77C355.74 606.253 351.267 601.78 345.75 601.78C340.233 601.78 335.76 606.253 335.76 611.77C335.76 617.287 340.233 621.76 345.75 621.76Z" fill="#BE7C5E"/>
    <path id="Vector_94" opacity="0.05" d="M337.7 601.87L340.22 604.95C340.22 604.95 351.29 605.95 352.55 603.41C353.81 600.87 345.26 599.06 345.26 599.06L337.7 601.87Z" fill="black"/>
    <path id="Vector_95" d="M337.7 601.59L340.22 604.67C340.22 604.67 351.29 605.67 352.55 603.13C353.81 600.59 345.26 598.78 345.26 598.78L337.7 601.59Z" fill="#444444"/>
    <path id="Vector_96" d="M335.74 612.38C336.127 612.38 336.44 611.816 336.44 611.12C336.44 610.424 336.127 609.86 335.74 609.86C335.353 609.86 335.04 610.424 335.04 611.12C335.04 611.816 335.353 612.38 335.74 612.38Z" fill="#BE7C5E"/>
    <path id="Vector_97" d="M355.78 612.38C356.167 612.38 356.48 611.816 356.48 611.12C356.48 610.424 356.167 609.86 355.78 609.86C355.393 609.86 355.08 610.424 355.08 611.12C355.08 611.816 355.393 612.38 355.78 612.38Z" fill="#BE7C5E"/>
    <path id="Vector_98" d="M337.94 597.09C338.722 596.092 339.8 595.367 341.02 595.02C343.23 594.14 345.53 593.24 347.9 593.4C348.131 593.397 348.357 593.463 348.55 593.59C348.709 593.759 348.84 593.951 348.94 594.16C349.66 595.23 351.68 595.16 351.94 596.42C352.01 596.83 351.84 597.27 351.94 597.67C352.2 598.67 353.61 598.57 354.44 599.14C354.882 599.539 355.15 600.095 355.188 600.689C355.225 601.283 355.028 601.869 354.64 602.32C353.817 603.193 352.751 603.799 351.58 604.06C349.525 604.848 347.291 605.045 345.13 604.63C342.1 603.84 335.01 601.88 337.94 597.09Z" fill="#444444"/>
    </g>
    <g class="tabletlines">
    <path id="Vector_99" d="M378.97 413.83H215.64V420.93H378.97V413.83Z" fill="white"/>
    <path id="Vector_100" d="M378.97 428.03H215.64V435.13H378.97V428.03Z" fill="white"/>
    <path id="Vector_101" d="M307.96 442.23H215.64V449.33H307.96V442.23Z" fill="white"/>
    <path id="Vector_102" d="M350.57 456.44H215.64V463.54H350.57V456.44Z" fill="white"/>
    <path id="Vector_103" d="M329.26 470.64H215.64V477.74H329.26V470.64Z" fill="white"/>
    <path id="Vector_104" d="M378.97 484.84H215.64V491.94H378.97V484.84Z" fill="white"/>
    </g>
    <path class="tabletparagraph" d="M364.77 506.15H222.74V562.96H364.77V506.15Z" fill="white"/>
    <g class="tabletman">
    <path id="Vector_105" d="M300.22 580.8H212.28V673.14H300.22V580.8Z" fill="url(#paint13_linear)"/>
    <path id="Vector_106" d="M296.4 584.82H216.1V669.13H296.4V584.82Z" fill="#6C63FF"/>
    <g id="Group_5" opacity="0.5">
    <path id="Vector_107" opacity="0.5" d="M263.08 622.32L262.08 621.96L262.02 622.05H261.96V619.33C261.96 618.995 261.933 618.661 261.88 618.33C263.5 617.138 264.74 615.502 265.45 613.62C266.851 611.805 267.714 609.633 267.944 607.352C268.173 605.07 267.758 602.77 266.747 600.713C265.735 598.655 264.167 596.922 262.221 595.71C260.275 594.498 258.028 593.855 255.735 593.855C253.442 593.855 251.195 594.498 249.249 595.71C247.303 596.922 245.735 598.655 244.723 600.713C243.712 602.77 243.297 605.07 243.526 607.352C243.756 609.633 244.619 611.805 246.02 613.62C246.73 615.502 247.97 617.138 249.59 618.33C249.537 618.661 249.51 618.995 249.51 619.33V622.33C244.144 623.811 239.412 627.012 236.039 631.441C232.667 635.87 230.841 641.283 230.84 646.85V669.16H281.69V646.81C281.687 641.256 279.866 635.856 276.505 631.434C273.145 627.012 268.43 623.811 263.08 622.32Z" fill="url(#paint14_linear)"/>
    </g>
    <path id="Vector_108" d="M254.7 621.76H257.8C260.811 621.76 263.793 622.353 266.575 623.505C269.357 624.658 271.885 626.347 274.014 628.476C276.143 630.605 277.832 633.133 278.985 635.915C280.137 638.697 280.73 641.679 280.73 644.69V667.69H231.73V644.69C231.73 641.675 232.324 638.69 233.479 635.906C234.634 633.121 236.327 630.592 238.46 628.462C240.594 626.332 243.126 624.644 245.913 623.494C248.699 622.344 251.685 621.755 254.7 621.76V621.76Z" fill="#514ABF"/>
    <path id="Vector_109" d="M256.13 613.77H255.38C252.276 613.77 249.76 616.286 249.76 619.39V623.14C249.76 626.244 252.276 628.76 255.38 628.76H256.13C259.234 628.76 261.75 626.244 261.75 623.14V619.39C261.75 616.286 259.234 613.77 256.13 613.77Z" fill="#FDA57D"/>
    <path id="Vector_110" opacity="0.1" d="M240.97 640.11C240.97 640.11 238.59 663.8 240.13 665.34Z" fill="black"/>
    <path id="Vector_111" opacity="0.1" d="M270.41 640.11C270.41 640.11 272.79 663.8 271.25 665.34Z" fill="black"/>
    <path id="Vector_112" opacity="0.05" d="M255.75 621C257.88 621 259.954 620.321 261.67 619.06C261.444 617.652 260.724 616.371 259.64 615.445C258.555 614.52 257.176 614.011 255.75 614.01V614.01C254.324 614.011 252.945 614.52 251.86 615.445C250.776 616.371 250.056 617.652 249.83 619.06C251.546 620.321 253.62 621 255.75 621V621Z" fill="black"/>
    <path id="Vector_113" d="M255.75 618.88C262.272 618.88 267.56 613.592 267.56 607.07C267.56 600.548 262.272 595.26 255.75 595.26C249.228 595.26 243.94 600.548 243.94 607.07C243.94 613.592 249.228 618.88 255.75 618.88Z" fill="#444444"/>
    <path id="Vector_114" d="M255.75 620.76C261.267 620.76 265.74 616.287 265.74 610.77C265.74 605.253 261.267 600.78 255.75 600.78C250.233 600.78 245.76 605.253 245.76 610.77C245.76 616.287 250.233 620.76 255.75 620.76Z" fill="#FDA57D"/>
    <path id="Vector_115" opacity="0.05" d="M247.7 600.87L250.22 603.95C250.22 603.95 261.29 604.95 262.55 602.41C263.81 599.87 255.26 598.06 255.26 598.06L247.7 600.87Z" fill="black"/>
    <path id="Vector_116" d="M247.7 600.59L250.22 603.67C250.22 603.67 261.29 604.67 262.55 602.13C263.81 599.59 255.26 597.78 255.26 597.78L247.7 600.59Z" fill="#444444"/>
    <path id="Vector_117" d="M245.74 611.38C246.127 611.38 246.44 610.816 246.44 610.12C246.44 609.424 246.127 608.86 245.74 608.86C245.353 608.86 245.04 609.424 245.04 610.12C245.04 610.816 245.353 611.38 245.74 611.38Z" fill="#FDA57D"/>
    <path id="Vector_118" d="M265.78 611.38C266.167 611.38 266.48 610.816 266.48 610.12C266.48 609.424 266.167 608.86 265.78 608.86C265.393 608.86 265.08 609.424 265.08 610.12C265.08 610.816 265.393 611.38 265.78 611.38Z" fill="#FDA57D"/>
    <path id="Vector_119" d="M248.81 607C249.314 606.108 249.732 605.171 250.06 604.2C250.591 603.05 251.429 602.069 252.482 601.366C253.535 600.663 254.763 600.264 256.028 600.215C257.293 600.165 258.548 600.466 259.653 601.085C260.758 601.703 261.671 602.615 262.29 603.72C263.11 605.19 263.38 606.93 264.29 608.35C264.419 608.447 264.567 608.516 264.724 608.553C264.881 608.59 265.044 608.594 265.203 608.565C265.362 608.535 265.512 608.474 265.646 608.384C265.78 608.293 265.894 608.176 265.98 608.04C266.138 607.751 266.23 607.431 266.249 607.102C266.268 606.774 266.214 606.445 266.09 606.14C265.88 605.52 265.53 604.95 265.3 604.34C265.071 603.347 264.735 602.382 264.3 601.46C263.765 600.762 263.082 600.192 262.3 599.79C260.09 598.43 257.53 597.02 255.03 597.79C254.186 598.078 253.388 598.486 252.66 599L249.48 601C248.457 601.573 247.544 602.321 246.78 603.21C246.09 604.11 244.78 606.8 245.39 607.96C246.11 609.28 248.18 607.83 248.81 607Z" fill="#444444"/>
    <path id="Vector_120" d="M253.24 628V667.73H259.11L258.24 628H253.24Z" fill="white"/>
    <path id="Vector_121" d="M252.26 627.46L255.66 632.41L259.24 627.46H252.26Z" fill="#FDA57D"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear" x1="158.44" y1="471.683" x2="158.44" y2="198.874" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="10054.5" y1="27178.8" x2="10054.5" y2="22880.7" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="11715.6" y1="27554" x2="11715.6" y2="24319.9" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="261273" y1="13939.7" x2="261272" y2="13526.6" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint4_linear" x1="437735" y1="223089" x2="437735" y2="77204.9" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint5_linear" x1="70858.1" y1="23771.9" x2="70858.1" y2="15245.2" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint6_linear" x1="47109.7" y1="21489.1" x2="49692.9" y2="21489.1" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint7_linear" x1="70903.5" y1="34111.2" x2="70903.5" y2="25584.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint8_linear" x1="2635.38" y1="5270.03" x2="2635.38" y2="0" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint9_linear" x1="123928" y1="363609" x2="123669" y2="178176" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint10_linear" x1="303.944" y1="729.026" x2="303.438" y2="367.018" gradientUnits="userSpaceOnUse">
    <stop stop-opacity="0.09"/>
    <stop offset="0.55" stop-opacity="0.07"/>
    <stop offset="1" stop-opacity="0.02"/>
    </linearGradient>
    <linearGradient id="paint11_linear" x1="30751.5" y1="62831.9" x2="30751.5" y2="54305.2" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint12_linear" x1="23843.5" y1="53420.4" x2="26429.3" y2="53420.4" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint13_linear" x1="22746.9" y1="62738.6" x2="22746.9" y2="54211.9" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    <linearGradient id="paint14_linear" x1="19177" y1="53344.1" x2="21762.8" y2="53344.1" gradientUnits="userSpaceOnUse">
    <stop stop-color="#808080" stop-opacity="0.25"/>
    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
    </linearGradient>
    </defs>
    </svg>
 );
}