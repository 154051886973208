import * as React from 'react';

export default function Videography(props) {
  return <svg width="360" height="276" viewBox="0 0 1151 676" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
  <path class="videobg" opacity="0.1" d="M1119.63 627.5C1118.55 628.29 1117.44 629.05 1116.32 629.8C1112.19 632.57 1107.88 635.059 1103.41 637.25C1079.95 648.95 1051.05 654.66 1022.89 658.54C959.74 667.26 895.26 668.95 831.05 670.61C657.58 675.12 483.65 679.61 310.62 669.23C242.26 665.13 172.37 658.23 110.41 637.23C101.537 634.223 92.83 630.91 84.29 627.29C67.03 619.94 50.45 611.01 36.66 600.37C23.66 590.37 13.2 578.87 6.92002 565.81C-6.17998 538.59 0.750018 508.02 15.7 481.35C21.949 470.527 28.967 460.167 36.7 450.35C48.22 435.33 60.93 420.78 72.55 405.78C113.87 352.36 140.83 284.89 102.69 230.17C91.69 214.45 75.88 200.65 66.95 184.24C55.54 163.24 56.43 139.93 60.06 117.24C60.52 114.38 61.06 111.54 61.55 108.7C67.78 75.13 79.38 38.95 115.29 17.99C152.59 -3.78998 205.54 -2.68999 251.85 5.10001C323.85 17.22 390.27 42.53 457.34 65.39C524.41 88.25 594.66 109.13 668.42 111.39C733.54 113.39 809.51 98.52 852.73 59.93C894.73 22.45 973.63 19.6 1030.53 42.24C1069.53 57.78 1098.68 85.77 1110.97 117.24C1113.45 123.541 1115.25 130.093 1116.32 136.78C1117.32 142.869 1117.65 149.049 1117.32 155.21C1117.15 158.21 1116.82 161.26 1116.32 164.21C1106.82 226.34 1038.41 275.34 996.83 330.84C976.51 357.95 962.16 390.51 976.13 419.74C997.44 464.28 1071.55 480.11 1116.32 513.27C1124.68 519.237 1132.1 526.432 1138.32 534.61C1159.97 564.13 1151.96 603.97 1119.63 627.5Z" fill="#6C63FF"/>
  <g class="flowers">
  <path id="Vector" d="M852.65 331.15C848.55 341.37 855.14 378.41 855.14 378.41C855.14 378.41 885.53 356.22 889.63 346.01C890.606 343.581 891.093 340.983 891.065 338.365C891.037 335.747 890.493 333.16 889.465 330.752C888.437 328.344 886.945 326.163 885.074 324.332C883.203 322.5 880.989 321.056 878.56 320.08C876.131 319.104 873.533 318.617 870.915 318.645C868.297 318.673 865.71 319.217 863.302 320.245C860.894 321.273 858.713 322.765 856.882 324.636C855.05 326.507 853.606 328.721 852.63 331.15H852.65Z" fill="#60AB25"/>
  <path id="Vector_2" d="M799.36 375.44C801.47 386.24 827.14 413.75 827.14 413.75C827.14 413.75 840.59 378.61 838.48 367.81C837.414 362.675 834.368 358.167 830.001 355.263C825.633 352.359 820.298 351.294 815.15 352.298C810.003 353.302 805.459 356.294 802.503 360.626C799.547 364.958 798.418 370.281 799.36 375.44V375.44Z" fill="#60AB25"/>
  <path id="Vector_3" d="M761.87 472.64C769.14 480.9 805.18 491.64 805.18 491.64C805.18 491.64 799.05 454.52 791.78 446.26C790.051 444.296 787.952 442.692 785.604 441.539C783.255 440.386 780.702 439.707 778.091 439.541C775.48 439.375 772.861 439.725 770.385 440.57C767.909 441.416 765.624 442.741 763.66 444.47C761.696 446.199 760.092 448.298 758.939 450.646C757.786 452.995 757.107 455.548 756.941 458.159C756.775 460.77 757.125 463.389 757.97 465.865C758.816 468.341 760.141 470.626 761.87 472.59V472.64Z" fill="#60AB25"/>
  <path id="Vector_4" d="M750.05 552.57C755.82 561.94 789.53 578.67 789.53 578.67C789.53 578.67 789.76 541.04 783.99 531.67C782.628 529.421 780.834 527.464 778.712 525.911C776.589 524.358 774.181 523.241 771.625 522.623C769.069 522.005 766.416 521.899 763.819 522.312C761.222 522.724 758.732 523.646 756.493 525.025C754.254 526.404 752.31 528.212 750.772 530.345C749.235 532.479 748.136 534.896 747.537 537.456C746.938 540.017 746.852 542.67 747.283 545.264C747.714 547.858 748.655 550.341 750.05 552.57Z" fill="#60AB25"/>
  <path id="Vector_5" d="M865.22 431.33C855.03 435.5 817.95 429.16 817.95 429.16C817.95 429.16 839.95 398.62 850.11 394.45C854.982 392.541 860.41 392.624 865.221 394.682C870.032 396.74 873.841 400.608 875.825 405.45C877.808 410.292 877.808 415.72 875.824 420.562C873.84 425.405 870.031 429.272 865.22 431.33V431.33Z" fill="#60AB25"/>
  <g class="leaftwo">
  <path id="Vector_6" d="M751.92 644.6C751.92 644.6 809.92 571.13 805.43 498.1C803.54 467.44 811.09 436.92 827.96 411.25C835.985 399.089 845.11 387.691 855.22 377.2" stroke="#535461" stroke-width="2" stroke-miterlimit="10"/>
  <path id="Vector_7" d="M850.22 524.63C839.22 525.57 805.76 508.42 805.76 508.42C805.76 508.42 835.87 485.86 846.83 484.92C849.462 484.651 852.12 484.909 854.651 485.678C857.182 486.447 859.535 487.712 861.572 489.4C863.609 491.087 865.289 493.164 866.516 495.507C867.742 497.851 868.489 500.416 868.714 503.051C868.939 505.687 868.638 508.341 867.826 510.859C867.015 513.377 865.711 515.708 863.989 517.716C862.268 519.725 860.164 521.371 857.8 522.558C855.436 523.745 852.859 524.449 850.22 524.63V524.63Z" fill="#60AB25"/>
  <path id="Vector_8" d="M823.14 613.32C812.49 616.09 776.59 604.85 776.59 604.85C776.59 604.85 802.45 577.53 813.1 574.75C818.159 573.584 823.474 574.439 827.913 577.132C832.352 579.825 835.565 584.143 836.868 589.169C838.171 594.195 837.461 599.531 834.89 604.041C832.318 608.552 828.088 611.881 823.1 613.32H823.14Z" fill="#60AB25"/>
  <path id="Vector_9" opacity="0.25" d="M852.65 331.15C848.55 341.37 855.14 378.41 855.14 378.41C855.14 378.41 885.53 356.22 889.63 346.01C890.606 343.581 891.093 340.983 891.065 338.365C891.037 335.747 890.493 333.16 889.465 330.752C888.437 328.344 886.945 326.163 885.074 324.332C883.203 322.5 880.989 321.056 878.56 320.08C876.131 319.104 873.533 318.617 870.915 318.645C868.297 318.673 865.71 319.217 863.302 320.245C860.894 321.273 858.713 322.765 856.882 324.636C855.05 326.507 853.606 328.721 852.63 331.15H852.65Z" fill="black"/>
  <path id="Vector_10" opacity="0.25" d="M799.36 375.44C801.47 386.24 827.14 413.75 827.14 413.75C827.14 413.75 840.59 378.61 838.48 367.81C837.414 362.675 834.368 358.167 830.001 355.263C825.633 352.359 820.298 351.294 815.15 352.298C810.003 353.302 805.459 356.294 802.503 360.626C799.547 364.958 798.418 370.281 799.36 375.44V375.44Z" fill="black"/>
  <path id="Vector_11" opacity="0.25" d="M761.87 472.64C769.14 480.9 805.18 491.64 805.18 491.64C805.18 491.64 799.05 454.52 791.78 446.26C790.051 444.296 787.952 442.692 785.604 441.539C783.255 440.386 780.702 439.707 778.091 439.541C775.48 439.375 772.861 439.725 770.385 440.57C767.909 441.416 765.624 442.741 763.66 444.47C761.696 446.199 760.092 448.298 758.939 450.646C757.786 452.995 757.107 455.548 756.941 458.159C756.775 460.77 757.125 463.389 757.97 465.865C758.816 468.341 760.141 470.626 761.87 472.59V472.64Z" fill="black"/>
  <path id="Vector_12" opacity="0.25" d="M750.05 552.57C755.82 561.94 789.53 578.67 789.53 578.67C789.53 578.67 789.76 541.04 783.99 531.67C782.628 529.421 780.834 527.464 778.712 525.911C776.589 524.358 774.181 523.241 771.625 522.623C769.069 522.005 766.416 521.899 763.819 522.312C761.222 522.724 758.732 523.646 756.493 525.025C754.254 526.404 752.31 528.212 750.772 530.345C749.235 532.479 748.136 534.896 747.537 537.456C746.938 540.017 746.852 542.67 747.283 545.264C747.714 547.858 748.655 550.341 750.05 552.57Z" fill="black"/>
  <path id="Vector_13" opacity="0.25" d="M865.22 431.33C855.03 435.5 817.95 429.16 817.95 429.16C817.95 429.16 839.95 398.62 850.11 394.45C854.982 392.541 860.41 392.624 865.221 394.682C870.032 396.74 873.841 400.608 875.825 405.45C877.808 410.292 877.808 415.72 875.824 420.562C873.84 425.405 870.031 429.272 865.22 431.33V431.33Z" fill="black"/>
  <path id="Vector_14" d="M788 556.65C789.48 567.55 813.51 596.5 813.51 596.5C813.51 596.5 828.97 562.2 827.51 551.29C827.158 548.696 826.299 546.198 824.981 543.936C823.663 541.675 821.913 539.695 819.83 538.11C817.747 536.524 815.373 535.365 812.842 534.698C810.311 534.03 807.674 533.868 805.08 534.22C802.486 534.572 799.987 535.431 797.726 536.749C795.464 538.067 793.485 539.817 791.9 541.9C790.314 543.983 789.155 546.357 788.488 548.888C787.82 551.419 787.658 554.056 788.01 556.65H788Z" fill="#47D92F"/>
  </g>
  <g class="leafone">
  <path id="Vector_15" opacity="0.25" d="M850.22 524.63C839.22 525.57 805.76 508.42 805.76 508.42C805.76 508.42 835.87 485.86 846.83 484.92C849.462 484.651 852.12 484.909 854.651 485.678C857.182 486.447 859.535 487.712 861.572 489.4C863.609 491.087 865.289 493.164 866.516 495.507C867.742 497.851 868.489 500.416 868.714 503.051C868.939 505.687 868.638 508.341 867.826 510.859C867.015 513.377 865.711 515.708 863.989 517.716C862.268 519.725 860.164 521.371 857.8 522.558C855.436 523.745 852.859 524.449 850.22 524.63V524.63Z" fill="black"/>
  <path id="Vector_16" opacity="0.25" d="M823.14 613.32C812.49 616.09 776.59 604.85 776.59 604.85C776.59 604.85 802.45 577.53 813.1 574.75C818.159 573.584 823.474 574.439 827.913 577.132C832.352 579.825 835.565 584.143 836.868 589.169C838.171 594.195 837.461 599.531 834.89 604.041C832.318 608.552 828.088 611.881 823.1 613.32H823.14Z" fill="black"/>
  <path id="Vector_17" d="M752.42 641.53C752.42 641.53 835.22 597.88 860.7 529.29C871.4 500.49 890.7 475.65 916.49 459.02C928.759 451.154 941.723 444.43 955.22 438.93" stroke="#535461" stroke-width="2" stroke-miterlimit="10"/>
  <path id="Vector_18" d="M971.52 395.78C963.64 403.45 954.65 439.99 954.65 439.99C954.65 439.99 991.42 431.99 999.31 424.35C1003.02 420.647 1005.11 415.633 1005.15 410.394C1005.19 405.155 1003.16 400.112 999.508 396.357C995.855 392.601 990.87 390.435 985.632 390.327C980.394 390.219 975.325 392.178 971.52 395.78V395.78Z" fill="#60AB25"/>
  <path id="Vector_19" d="M904.86 414.69C902.41 425.42 914.73 460.97 914.73 460.97C914.73 460.97 941.26 434.3 943.73 423.57C944.373 420.998 944.496 418.324 944.092 415.704C943.688 413.084 942.766 410.571 941.379 408.311C939.992 406.052 938.168 404.092 936.014 402.547C933.86 401.001 931.42 399.901 928.835 399.31C926.251 398.72 923.575 398.65 920.963 399.107C918.352 399.563 915.858 400.536 913.627 401.968C911.396 403.4 909.474 405.263 907.972 407.448C906.47 409.632 905.419 412.094 904.88 414.69H904.86Z" fill="#47D92F"/>
  <path id="Vector_20" d="M831.22 488.36C834.52 498.86 863.1 523.36 863.1 523.36C863.1 523.36 872.54 486.94 869.24 476.44C868.498 473.89 867.254 471.515 865.581 469.453C863.909 467.39 861.84 465.683 859.499 464.432C857.157 463.18 854.588 462.409 851.945 462.164C849.301 461.918 846.634 462.204 844.102 463.003C841.57 463.803 839.223 465.101 837.2 466.82C835.176 468.539 833.516 470.645 832.318 473.015C831.12 475.385 830.407 477.97 830.222 480.619C830.037 483.268 830.383 485.927 831.24 488.44L831.22 488.36Z" fill="#47D92F"/>
  <path id="Vector_21" d="M942.37 492.45C931.37 492.14 900.03 471.31 900.03 471.31C900.03 471.31 932.5 452.31 943.5 452.61C948.732 452.832 953.667 455.105 957.236 458.937C960.806 462.769 962.724 467.852 962.576 473.087C962.427 478.322 960.224 483.288 956.443 486.911C952.662 490.535 947.606 492.524 942.37 492.45V492.45Z" fill="#60AB25"/>
  <path id="Vector_22" d="M890.85 571.67C880.45 568.08 856.78 538.84 856.78 538.84C856.78 538.84 893.44 530.41 903.85 534C908.735 535.809 912.717 539.458 914.945 544.166C917.173 548.874 917.469 554.268 915.769 559.192C914.07 564.115 910.511 568.178 905.853 570.511C901.196 572.843 895.81 573.259 890.85 571.67V571.67Z" fill="#60AB25"/>
  <path id="Vector_23" d="M830.22 641.78C819.36 640 791.08 615.18 791.08 615.18C791.08 615.18 825.8 600.67 836.66 602.45C841.8 603.385 846.368 606.302 849.379 610.571C852.39 614.841 853.604 620.123 852.76 625.279C851.916 630.435 849.081 635.053 844.865 638.139C840.649 641.226 835.39 642.533 830.22 641.78V641.78Z" fill="#47D92F"/>
  </g>
  </g>
  <path class="videostand" d="M759.68 647.63H750.81L685.93 312.7L677.61 647.63H668.19L657.65 313.26L594.43 647.63H584.45L627.42 303.38L628.82 292.19L713.11 282.76L715.73 303.38L759.68 647.63Z" fill="#464353"/>
  <g class="videocamera">
  <path id="Vector_24" d="M580.63 215.62H526.98V248.89H580.63V215.62Z" fill="#464353"/>
  <path id="Vector_25" d="M838.27 201.07L870.71 187.97H877.82C878.857 187.97 879.852 188.382 880.585 189.115C881.318 189.848 881.73 190.843 881.73 191.88V283.24C881.73 284.277 881.318 285.272 880.585 286.005C879.852 286.738 878.857 287.15 877.82 287.15H870.71L838.27 273.64V201.07Z" fill="#464353"/>
  <path id="Vector_26" d="M838.27 205.23H771.94V269.48H838.27V205.23Z" fill="#464353"/>
  <path id="Vector_27" d="M699.5 84.55H656.8V92.31H699.5V84.55Z" fill="#514E7F"/>
  <path id="Vector_28" d="M728.27 94.6H608.08V182.35H728.27V94.6Z" fill="#514E7F"/>
  <path id="Vector_29" opacity="0.1" d="M728.27 171.96H608.08V182.36H728.27V171.96Z" fill="black"/>
  <path id="Vector_30" opacity="0.1" d="M773.6 205.23H770.91V269.48H773.6V205.23Z" fill="black"/>
  <path id="Vector_31" opacity="0.1" d="M580.63 215.62H562.33V248.89H580.63V215.62Z" fill="black"/>
  <path id="Vector_32" opacity="0.1" d="M715.73 303.38H627.42L628.82 292.19L713.11 282.76L715.73 303.38Z" fill="black"/>
  <path id="Vector_33" d="M761.23 173.62H574.69C568.781 173.62 563.99 178.411 563.99 184.32V291.01C563.99 296.919 568.781 301.71 574.69 301.71H761.23C767.139 301.71 771.93 296.919 771.93 291.01V184.32C771.93 178.411 767.139 173.62 761.23 173.62Z" fill="#514E7F"/>
  <path id="Vector_34" d="M837.64 201.07H838.27V273.64H837.64C836.264 273.64 834.943 273.093 833.97 272.12C832.997 271.147 832.45 269.826 832.45 268.45V206.26C832.45 204.884 832.997 203.563 833.97 202.59C834.943 201.617 836.264 201.07 837.64 201.07Z" fill="#514E7F"/>
  <path id="Vector_35" d="M870.71 188H877.44C878.578 188 879.669 188.452 880.474 189.257C881.278 190.061 881.73 191.152 881.73 192.29V282.91C881.73 284.048 881.278 285.139 880.474 285.943C879.669 286.748 878.578 287.2 877.44 287.2H870.71V188Z" fill="#514E7F"/>
  <path id="Vector_36" opacity="0.1" d="M536.55 215.62H526.98V248.89H536.55V215.62Z" fill="black"/>
  <path id="Vector_37" d="M527.68 205.23H523.37C519.394 205.23 516.17 208.454 516.17 212.43V254.59C516.17 258.566 519.394 261.79 523.37 261.79H527.68C531.656 261.79 534.88 258.566 534.88 254.59V212.43C534.88 208.454 531.656 205.23 527.68 205.23Z" fill="#514E7F"/>
  </g>
  <g class="lensbig">
  <path id="Vector_38" d="M608.08 154.49C641.156 154.49 667.97 127.676 667.97 94.6C667.97 61.5237 641.156 34.71 608.08 34.71C575.004 34.71 548.19 61.5237 548.19 94.6C548.19 127.676 575.004 154.49 608.08 154.49Z" fill="#464353"/>
  <path id="Vector_39" d="M608.08 139.52C632.889 139.52 653 119.409 653 94.6C653 69.7914 632.889 49.68 608.08 49.68C583.271 49.68 563.16 69.7914 563.16 94.6C563.16 119.409 583.271 139.52 608.08 139.52Z" fill="#fa5f06a9"/>
  </g>
  <g class="smalllens">
  <path id="Vector_40" d="M719.12 139.52C743.238 139.52 762.79 119.968 762.79 95.85C762.79 71.7317 743.238 52.18 719.12 52.18C695.002 52.18 675.45 71.7317 675.45 95.85C675.45 119.968 695.002 139.52 719.12 139.52Z" fill="#464353"/>
  <path id="Vector_41" d="M719.12 128.6C737.207 128.6 751.87 113.937 751.87 95.85C751.87 77.7627 737.207 63.1 719.12 63.1C701.033 63.1 686.37 77.7627 686.37 95.85C686.37 113.937 701.033 128.6 719.12 128.6Z" fill="#ffb702c5"/>
  </g>
  <g class="lenslights">
  <path id="Vector_42" d="M715.73 198.43C718.906 198.43 721.48 195.856 721.48 192.68C721.48 189.504 718.906 186.93 715.73 186.93C712.554 186.93 709.98 189.504 709.98 192.68C709.98 195.856 712.554 198.43 715.73 198.43Z" fill="#FF6F31"/>
  <path id="Vector_43" opacity="0.5" d="M734.02 198.43C737.196 198.43 739.77 195.856 739.77 192.68C739.77 189.504 737.196 186.93 734.02 186.93C730.844 186.93 728.27 189.504 728.27 192.68C728.27 195.856 730.844 198.43 734.02 198.43Z" fill="#FFB802"/>
  </g>
  <g class="videoman">
  <path id="Vector_44" opacity="0.1" d="M537.69 659.86C686.608 659.86 807.33 655.759 807.33 650.7C807.33 645.641 686.608 641.54 537.69 641.54C388.772 641.54 268.05 645.641 268.05 650.7C268.05 655.759 388.772 659.86 537.69 659.86Z" fill="#6C63FF"/>
  <path id="Vector_45" d="M459.64 109.7L461.8 108.26L467.89 121.11L465.55 123.86H458.47C458.47 123.86 458.72 116.86 458.89 116.61C459.06 116.36 459.64 109.7 459.64 109.7Z" fill="#464353"/>
  <path id="Vector_46" d="M417.83 619.05V646.55C417.83 646.55 413.33 654.92 397.83 655.3C395.948 655.411 394.078 654.935 392.478 653.937C390.878 652.939 389.628 651.469 388.9 649.73C386.74 645.35 386.49 639.21 386.71 634.48C386.82 632.114 387.071 629.757 387.46 627.42L417.83 619.05Z" fill="#3F3D56"/>
  <path id="Vector_47" opacity="0.1" d="M417.83 619.05V627.56L386.71 634.48C386.82 632.114 387.071 629.757 387.46 627.42L417.83 619.05Z" fill="black"/>
  <path id="Vector_48" d="M423.16 624.38L384.16 633.05L377.5 606.71C376.546 605.374 375.697 603.967 374.96 602.5C374.53 601.66 374.11 600.77 373.71 599.84C366.45 583.23 363.22 553.27 361.84 527.78C361.77 526.49 361.7 525.21 361.64 523.95C360.52 500.89 360.83 482.39 360.83 482.39C360.83 482.39 365.5 459.72 360.83 451.06C357.23 444.38 356.01 434.34 355.64 430.06C355.54 428.79 355.5 428.06 355.5 428.06L356.22 426.17L359.16 418.39H391.5L410.3 434.3L413.16 436.72L412.84 438.72C412.03 443.66 409.89 456.72 408.84 463.1C408.087 468.316 408.315 473.627 409.51 478.76C409.51 478.76 409.9 481.3 410.51 485.33C410.66 486.33 410.83 487.39 411.02 488.54C413.41 503.32 417.89 530.31 419.8 536.76C420.33 538.53 420.8 542.97 421.15 548.98C421.23 550.18 421.3 551.43 421.37 552.74C422.77 578.36 423.16 624.38 423.16 624.38Z" fill="#514E7F"/>
  <path id="Vector_49" opacity="0.1" d="M417.83 646.27V646.55C417.83 646.55 413.33 654.92 397.83 655.3C395.948 655.411 394.078 654.935 392.478 653.937C390.878 652.939 389.628 651.469 388.9 649.73C395.85 651.44 407.13 652.54 417.83 646.27Z" fill="black"/>
  <path id="Vector_50" d="M664.5 260.38C654.22 271.05 643.22 251.21 636.57 235.54C632.84 226.72 630.5 219.21 630.5 219.21L657.86 207.63C657.86 207.63 659.54 210.3 661.65 214.53C667.32 225.83 676.15 248.25 664.5 260.38Z" fill="#A1616A"/>
  <path id="Vector_51" d="M461.95 104.17C461.512 114.069 462.253 123.986 464.16 133.71C464.16 133.71 408.5 139.71 413.5 131.05C416.43 125.97 418.33 112.56 419.39 102.31C420.16 95.05 420.5 89.38 420.5 89.38C420.5 89.38 473.83 68.05 465.83 86.05C463.44 91.42 462.33 97.78 461.95 104.17Z" fill="#A1616A"/>
  <path id="Vector_52" d="M466.65 247.38L459.17 336.71L493.5 331.05L487.5 257.38L489.5 237.71L466.65 247.38Z" fill="#464353"/>
  <path id="Vector_53" opacity="0.1" d="M493.5 331.19L478.59 333.65L459.16 336.85L460.53 320.55L466.65 247.52L474.44 244.22L479.96 241.89L489.5 237.85L487.5 257.52L493.5 331.19Z" fill="black"/>
  <path id="Vector_54" d="M348.95 624.78C344.58 633.53 351.33 641.65 351.33 641.65C352.83 646.72 352.84 649.82 352.17 651.65C350.78 655.54 346.45 654.11 346.45 654.11C332.83 652.61 324.58 615.87 328.08 611.66C331.58 607.45 325.95 597.78 325.95 597.78L318.58 600.65L311.83 579.91L316.09 574.66L321.6 567.88L325.33 563.28L362.58 602.15C362.58 602.15 360.99 604.54 358.8 607.97C355.74 612.78 351.51 619.68 348.95 624.78Z" fill="#3F3D56"/>
  <path id="Vector_55" opacity="0.1" d="M421.4 552.69L374.96 602.5C366.82 586.6 363.32 554.7 361.84 527.77C370.49 520.57 378.84 514.37 385.16 511.38C385.16 511.38 406.54 495.91 411.05 488.49C413.44 503.27 417.92 530.26 419.83 536.71C420.46 538.83 420.98 544.78 421.4 552.69Z" fill="black"/>
  <path id="Vector_56" opacity="0.1" d="M410.3 434.29L356.22 426.16L359.16 418.38H391.5L410.3 434.29Z" fill="black"/>
  <path id="Vector_57" opacity="0.1" d="M421.22 548.93L373.75 599.84C366.45 583.23 363.22 553.27 361.84 527.78C361.77 526.49 361.7 525.21 361.64 523.95C370.37 516.67 378.82 510.41 385.16 507.39C385.16 507.39 405.11 492.96 410.54 485.29C410.69 486.29 410.86 487.35 411.05 488.5C413.44 503.28 417.92 530.27 419.83 536.72C420.36 538.48 420.8 542.92 421.22 548.93Z" fill="black"/>
  <path id="Vector_58" opacity="0.1" d="M362.58 602.17C362.58 602.17 360.99 604.56 358.8 607.99C350.85 597.94 332 574.8 321.6 567.9L325.33 563.3L362.58 602.17Z" fill="black"/>
  <path id="Vector_59" d="M483.16 459.37C480.5 469.71 480.5 482.04 480.5 482.04C480.5 482.04 480.16 504.37 458.16 513.04L455.53 514.04L364.53 611.67C364.53 611.67 330.86 567.67 319.86 565C319.86 565 364.19 519.33 385.19 509.33C385.19 509.33 410.53 491 411.86 484.67C413.19 478.34 420.53 475 420.53 475C420.53 475 414.86 453.67 417.19 448.33C418.469 445.066 418.717 441.489 417.9 438.08C417.28 436.08 416.08 434.89 414.21 435.94C413.849 436.143 413.514 436.388 413.21 436.67L355.55 428V398.37C355.55 398.37 341.55 382.04 343.55 362.04C345.55 342.04 341.21 342.71 341.21 342.71C341.21 342.71 341.42 308.93 344.27 306.11C347.12 303.29 374.5 290.37 374.5 290.37L460.34 305.62L463.88 322.04C463.88 322.04 466.88 335.1 471.88 340.9C476.88 346.7 471.88 353.69 471.88 353.69C471.88 353.69 473.55 368.69 478.55 382.04C483.55 395.39 485.83 449.04 483.16 459.37Z" fill="#514E7F"/>
  <path id="Vector_60" opacity="0.1" d="M447.5 133.71C447.5 133.71 425.22 123.38 423.83 122.04C422.44 120.7 376.22 239.38 376.22 239.38L363.22 298.38C363.22 298.38 432.55 328.04 447.55 324.04C462.55 320.04 463.88 324.04 463.88 324.04C463.88 324.04 479.22 238.04 484.22 230.38C489.22 222.72 479.55 144.71 479.55 144.71L462.22 122.03L447.5 133.71Z" fill="black"/>
  <path id="Vector_61" opacity="0.1" d="M447.5 129.71C447.5 129.71 425.22 119.38 423.83 118.04C422.44 116.7 376.22 235.38 376.22 235.38L363.22 294.38C363.22 294.38 432.55 324.04 447.55 320.04C462.55 316.04 463.88 320.04 463.88 320.04C463.88 320.04 479.22 234.04 484.22 226.38C489.22 218.72 479.55 140.71 479.55 140.71L462.22 118.03L447.5 129.71Z" fill="black"/>
  <path id="Vector_62" d="M484.16 228.38C483.16 229.97 481.65 234.91 479.96 241.75C478.78 246.51 477.5 252.18 476.19 258.28C470.29 285.81 463.83 322.05 463.83 322.05C463.83 322.05 463.42 320.83 460.53 320.41C458.19 320.07 454.21 320.26 447.53 322.05C432.53 326.05 363.19 296.38 363.19 296.38L365.19 287.38L376.19 237.38C376.19 237.38 422.53 118.71 423.86 120.05C424.67 120.86 433.33 125.05 439.93 128.16C444.14 130.16 447.53 131.71 447.53 131.71L461.53 120.65L462.3 120.04L479.55 142.71C479.55 142.71 489.16 220.71 484.16 228.38Z" fill="#6C63FF"/>
  <path id="Vector_63" opacity="0.1" d="M431.16 143.38C433.16 151.07 428.5 163.71 428.5 163.71C428.5 163.71 412.5 203.05 407.83 221.23C403.16 239.41 376.45 276.05 376.45 276.05C376.45 276.05 372.53 279.98 367.04 285.45C366.43 286.06 365.79 286.69 365.15 287.35L376.15 237.35C376.15 237.35 422.49 118.68 423.82 120.02C424.63 120.83 433.29 125.02 439.89 128.13C435.76 131.89 429.83 138.29 431.16 143.38Z" fill="black"/>
  <path id="Vector_64" d="M429.16 143.38C431.16 151.07 426.5 163.71 426.5 163.71C426.5 163.71 410.5 203.05 405.83 221.23C401.16 239.41 374.45 276.05 374.45 276.05C374.45 276.05 370.53 279.98 365.04 285.45C364.39 286.1 363.71 286.77 363.04 287.45C352.32 298.12 337.25 313.08 332.54 317.45C331.85 318.1 331.38 318.52 331.18 318.66C330.754 318.947 330.28 319.153 329.78 319.27C326.7 320.11 320.63 319.5 312.18 318.66C302.18 317.66 310.52 297 310.52 297L313.15 294.29L315.55 291.81L342.79 263.78L344.79 261.67L353.12 253.08L357.36 208.48L357.46 207.41C357.46 207.41 356.22 204.58 354.31 200.26C349.22 188.57 339.22 166.01 335.56 158.72C335.14 157.87 334.81 157.24 334.56 156.85C334.56 156.8 334.51 156.75 334.48 156.71C331.81 152.38 339.14 152.71 339.14 152.71C339.14 152.71 346.48 150.71 345.14 147.71C343.8 144.71 353.81 142.71 353.48 138.38C353.15 134.05 359.48 133.38 359.48 133.38C359.48 133.38 368.48 136.38 367.48 133.38C366.48 130.38 381.48 128.71 381.48 128.71C381.48 128.71 388.81 132.38 392.81 128.71C396.81 125.04 413.22 125.05 413.22 125.05C413.22 125.05 409.87 112.38 413.22 108.71C416.57 105.04 441.69 125.05 441.69 125.05C441.69 125.05 427.16 135.7 429.16 143.38Z" fill="#464353"/>
  <path id="Vector_65" opacity="0.1" d="M345.83 314.04C345.83 314.04 373.5 353.04 392.83 340.38C412.16 327.72 366.83 294.71 366.83 294.71L345.83 314.04Z" fill="black"/>
  <path id="Vector_66" d="M345.83 312.04C345.83 312.04 373.5 351.04 392.83 338.38C412.16 325.72 366.83 292.71 366.83 292.71L345.83 312.04Z" fill="#A1616A"/>
  <path id="Vector_67" opacity="0.1" d="M478.59 333.51C480.7 315.1 486.82 287.44 481.83 279.05C479.36 274.89 477.53 266.96 476.19 258.28C475.47 253.61 474.9 248.72 474.44 244.08C473.22 231.77 472.83 221.23 472.83 221.23C472.83 221.23 460.83 167.71 463.16 156.71C464.62 149.83 462.94 132.59 461.48 120.65L462.25 120.04L479.5 142.71C479.5 142.71 489.16 220.71 484.16 228.38C483.16 229.97 481.65 234.91 479.96 241.75L489.5 237.75L487.5 257.42L493.5 331.09L478.59 333.51Z" fill="black"/>
  <path id="Vector_68" d="M461.8 108.26C461.8 108.26 479.04 122.71 485.6 123.04C485.6 123.04 483.83 131.04 476.5 134.38C476.5 134.38 494.5 136.76 501.17 135.73C507.84 134.7 519.5 136.38 521.83 139.73C524.16 143.08 529.22 147.38 533.22 148.04C537.22 148.7 541.57 152.71 541.57 152.71L512.22 196.04C512.22 196.04 501.55 222.71 503.88 228.71C506.21 234.71 503.54 268.38 501.71 270.04C499.88 271.7 504.88 338.71 513.88 338.71C513.88 338.71 489.22 355.38 481.88 350.38C474.54 345.38 491.22 291.38 483.88 279.04C476.54 266.7 474.88 221.22 474.88 221.22C474.88 221.22 462.88 167.71 465.22 156.71C467.56 145.71 461.8 108.26 461.8 108.26Z" fill="#464353"/>
  <path id="Vector_69" opacity="0.1" d="M661.65 214.53C652.74 211.75 639.09 211.67 636.57 235.53C632.84 226.71 630.5 219.2 630.5 219.2L657.86 207.62C657.86 207.62 659.54 210.3 661.65 214.53Z" fill="black"/>
  <path id="Vector_70" d="M525.22 150.71L541.57 152.71C541.57 152.71 546.57 159.38 551.22 159.38C555.87 159.38 559.55 165.38 559.55 165.38C559.55 165.38 566.22 169.04 570.55 168.04C574.88 167.04 592.88 165.39 595.55 168.22C598.22 171.05 615.55 173.71 615.55 173.71C615.55 173.71 622.22 184.04 628.22 184.04C634.22 184.04 667.55 208.38 668.55 217.04C668.55 217.04 635.55 196.04 635.22 242.21C635.22 242.21 621.22 229.71 618.88 224.38C616.54 219.05 594.88 221.22 594.88 221.22C594.88 221.22 580.22 214.71 577.88 210.38C575.54 206.05 561.88 217.28 557.55 213.99C553.22 210.7 513.88 193.58 513.88 193.58L501.71 170.71L525.22 150.71Z" fill="#464353"/>
  <path id="Vector_71" d="M449.83 191.21C451.393 191.21 452.66 189.943 452.66 188.38C452.66 186.817 451.393 185.55 449.83 185.55C448.267 185.55 447 186.817 447 188.38C447 189.943 448.267 191.21 449.83 191.21Z" fill="#3F3D56"/>
  <path id="Vector_72" d="M447 250.3C448.563 250.3 449.83 249.033 449.83 247.47C449.83 245.907 448.563 244.64 447 244.64C445.437 244.64 444.17 245.907 444.17 247.47C444.17 249.033 445.437 250.3 447 250.3Z" fill="#3F3D56"/>
  <path id="Vector_73" d="M449.83 219.2C451.393 219.2 452.66 217.933 452.66 216.37C452.66 214.807 451.393 213.54 449.83 213.54C448.267 213.54 447 214.807 447 216.37C447 217.933 448.267 219.2 449.83 219.2Z" fill="#3F3D56"/>
  <path id="Vector_74" d="M441.66 281.4C443.223 281.4 444.49 280.133 444.49 278.57C444.49 277.007 443.223 275.74 441.66 275.74C440.097 275.74 438.83 277.007 438.83 278.57C438.83 280.133 440.097 281.4 441.66 281.4Z" fill="#3F3D56"/>
  <path id="Vector_75" opacity="0.1" d="M352.22 651.7C350.83 655.59 346.5 654.16 346.5 654.16C332.88 652.66 324.63 615.92 328.13 611.71C331.63 607.5 325.95 597.78 325.95 597.78L318.58 600.65L311.83 579.91L316.09 574.66C320.33 582.01 326.4 592.58 327.83 595.28C330.545 600.879 331.833 607.063 331.58 613.28C331.35 619.78 338.89 650.86 352.22 651.7Z" fill="black"/>
  <path id="Vector_76" opacity="0.1" d="M438.83 421.71C438.83 421.71 470.22 441.71 471.22 449.38L438.83 421.71Z" fill="black"/>
  <path id="Vector_77" opacity="0.1" d="M437.22 437.71C437.22 437.71 473.22 455.04 471.22 461.04L437.22 437.71Z" fill="black"/>
  <path id="Vector_78" opacity="0.1" d="M434.83 453.04C434.83 453.04 455.17 463.71 456.5 470.04L434.83 453.04Z" fill="black"/>
  <path id="Vector_79" opacity="0.1" d="M443.5 477.04C443.5 477.04 456.83 488.71 459.5 485.71C462.17 482.71 443.5 477.04 443.5 477.04Z" fill="black"/>
  <path id="Vector_80" opacity="0.1" d="M424.83 484.38C424.83 484.38 435.22 501.04 430.22 502.04C425.22 503.04 424.83 484.38 424.83 484.38Z" fill="black"/>
  <path id="Vector_81" opacity="0.1" d="M379.22 188.38C379.22 188.38 361.55 199.04 366.88 201.71C372.21 204.38 379.22 188.38 379.22 188.38Z" fill="black"/>
  <path id="Vector_82" opacity="0.1" d="M379.22 149.04C379.22 149.04 361.22 182.04 369.55 179.71C377.88 177.38 379.22 149.04 379.22 149.04Z" fill="black"/>
  <path id="Vector_83" opacity="0.1" d="M570.5 182.04C570.5 182.04 559.5 194.98 561.5 202.04L570.5 182.04Z" fill="black"/>
  <path id="Vector_84" opacity="0.1" d="M599.22 188.38C599.22 188.38 582.71 203.04 585.13 204.71L599.22 188.38Z" fill="black"/>
  <path id="Vector_85" opacity="0.1" d="M609.5 206.39C609.5 206.39 607.83 216.03 610.5 216.39L609.5 206.39Z" fill="black"/>
  <path id="Vector_86" opacity="0.05" d="M429.33 409.78C425.08 415.28 425.08 425.03 423.16 429.03C422.11 431.23 419.77 435.03 417.87 438.11C417.25 436.11 416.05 434.92 414.18 435.97C412.95 426.8 410.78 410.97 410.08 407.78C409.08 403.28 420.08 391.28 420.08 391.28C420.08 391.28 430.83 393.53 433.58 399.03C436.33 404.53 433.58 404.29 429.33 409.78Z" fill="black"/>
  <path id="Vector_87" opacity="0.1" d="M373.83 294.05C361.83 295.71 339.5 327.71 339.5 327.71C339.5 327.71 336.57 323.45 332.5 317.5C327.38 309.95 320.5 299.66 315.5 291.86L344.8 261.64L350.13 267.38C350.13 267.38 360.47 277.71 360.47 279.71C360.47 280.49 362.47 282.86 365.01 285.45C368.9 289.48 373.83 294.05 373.83 294.05Z" fill="black"/>
  <path id="Vector_88" opacity="0.1" d="M335.56 158.72C335.14 157.87 334.81 157.24 334.56 156.85L347.48 157.71L354.33 200.23C349.22 188.57 339.22 166.01 335.56 158.72Z" fill="black"/>
  <path id="Vector_89" opacity="0.1" d="M363.02 287.48C352.3 298.15 337.23 313.11 332.52 317.48C331.83 318.13 331.36 318.55 331.16 318.69C330.734 318.977 330.26 319.183 329.76 319.3C325.05 312.38 318.33 302.41 313.13 294.3L315.53 291.82L342.79 263.78L346.16 267.41C346.16 267.41 356.5 277.74 356.5 279.74C356.5 280.7 359.73 284.18 363.02 287.48Z" fill="black"/>
  <path id="Vector_90" d="M349.5 157.71L334.5 156.71C334.5 156.71 330.5 166.04 327.17 166.71C323.84 167.38 319.5 175.04 319.5 175.04C319.5 175.04 306.66 177.38 307.22 181.38C307.78 185.38 287.48 213.99 287.48 213.99C287.48 213.99 280.81 227.71 285.48 237.71C290.15 247.71 293.48 254.38 294.48 258.04C295.48 261.7 307.32 277.71 307.22 280.71C307.12 283.71 337.48 327.71 337.48 327.71C337.48 327.71 359.81 295.71 371.81 294.04C371.81 294.04 358.48 281.71 358.48 279.71C358.48 277.71 348.22 267.38 348.22 267.38L335.22 253.38C335.22 253.38 330.88 251.38 335.22 247.71C339.56 244.04 327.89 235.71 327.89 235.71C327.89 235.71 327.55 229.71 331.89 228.04C336.23 226.37 341.89 215.38 341.89 215.38L357.56 207.38L349.5 157.71Z" fill="#464353"/>
  <path id="Vector_91" opacity="0.1" d="M307.22 242.21C307.22 242.21 322.48 244.21 321.81 247.47C321.14 250.73 307.22 242.21 307.22 242.21Z" fill="black"/>
  <path id="Vector_92" opacity="0.1" d="M319.83 185.54C319.83 185.54 334.5 206.31 336.5 202.01L319.83 185.54Z" fill="black"/>
  </g>
  <g class="head">
  <path id="Vector_93" d="M472.65 79.78C472.645 87.6844 469.621 95.2883 464.196 101.037C458.771 106.786 451.355 110.245 443.465 110.708C435.574 111.171 427.804 108.602 421.745 103.526C415.685 98.451 411.793 91.2526 410.865 83.403C409.936 75.5534 412.041 67.6456 416.749 61.2964C421.457 54.9473 428.413 50.6364 436.194 49.2452C443.975 47.854 451.993 49.4876 458.61 53.812C465.226 58.1365 469.941 64.825 471.79 72.51C472.363 74.8909 472.651 77.3312 472.65 79.78V79.78Z" fill="#A1616A"/>
  <path id="Vector_94" d="M443.69 59.95C446.52 61 449.6 61.82 452.55 61.12C455.06 60.53 457.73 58.88 460.01 60.12C462.01 61.19 462.41 63.8 463.67 65.66C465.06 67.66 467.47 68.78 469.77 69.66C471.485 70.4597 473.319 70.9732 475.2 71.18C476.141 71.2721 477.091 71.1459 477.976 70.8111C478.86 70.4764 479.656 69.9422 480.3 69.25C482.08 67.1 481.54 63.88 480.48 61.31C479.42 58.74 477.92 56.18 478.03 53.4C478.16 50.1 480.55 47.23 480.75 43.94C481.01 39.77 477.64 36.13 473.82 34.44C470.4 32.93 466.49 32.51 463.36 30.44C459.79 28.1 457.83 24.04 455.19 20.68C451.35 15.79 445.34 12.14 439.19 12.84C435.4 13.27 431.96 15.25 428.19 16.02C422.46 17.21 416.4 15.48 410.7 16.81C409.323 17.0267 408.064 17.7161 407.14 18.76C405.74 20.64 406.46 23.27 407.08 25.53C408.19 29.6 408.66 34.21 406.45 37.79C403.95 41.84 398.64 43.55 396.33 47.71C395.662 48.9303 395.293 50.2917 395.253 51.6823C395.213 53.073 395.503 54.4533 396.1 55.71C397.2 58.01 399.33 59.88 399.66 62.41C399.89 64.23 399.14 66 398.75 67.78C398.36 69.56 398.47 71.78 399.94 72.78C401.41 73.78 403.47 73.53 404.6 74.78C404.942 75.2764 405.167 75.8436 405.259 76.4393C405.351 77.035 405.307 77.6437 405.13 78.22C404.73 80.22 403.88 82.03 403.43 83.99C403.141 84.9513 403.061 85.963 403.194 86.9579C403.326 87.9528 403.669 88.908 404.2 89.76C406.14 92.38 410.06 92.22 413.2 91.52C414.106 91.3758 414.97 91.0345 415.73 90.52C417.73 89.01 417.38 86.04 417.87 83.61C418.96 78.11 424.75 74.54 426.1 69.1C426.97 65.59 425.65 60.66 429.05 58.34C433.47 55.49 439.43 58.38 443.69 59.95Z" fill="#464353"/>
  </g>
  </g>

  </svg>
  

}