import * as React from "react";

export default function Graphics(props) {
  return (
    <svg width="360" height="300" viewBox="0 0 879 553" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="undraw_content_team_3epn 1" clip-path="url(#clip0)">
<g class="laptop">
<path id="Vector" d="M837.863 552.215H95.2294C88.6252 552.215 82.2916 549.591 77.6218 544.921C72.9519 540.252 70.3285 533.918 70.3285 527.314C339.533 496.027 603.678 496.027 862.764 527.314C862.764 533.918 860.14 540.252 855.471 544.921C850.801 549.591 844.467 552.215 837.863 552.215Z" fill="#CCCCCC"/>
<path id="Vector_2" d="M862.764 528.779L70.3285 527.314L162.169 372.782L162.608 372.049V31.4777C162.608 27.3439 163.422 23.2505 165.004 19.4313C166.586 15.6121 168.904 12.1419 171.827 9.21884C174.75 6.2958 178.22 3.97718 182.04 2.3954C185.859 0.813621 189.952 -0.000345318 194.086 1.09897e-07H734.612C738.746 -0.000345318 742.839 0.813621 746.658 2.3954C750.478 3.97718 753.948 6.2958 756.871 9.21884C759.794 12.1419 762.112 15.6121 763.694 19.4313C765.276 23.2505 766.09 27.3439 766.09 31.4777V374.393L862.764 528.779Z" fill="#E6E6E6"/>
<path id="Vector_3" d="M193.368 21.9714C190.65 21.9745 188.044 23.0557 186.122 24.9779C184.199 26.9001 183.118 29.5063 183.115 32.2247V342.754C183.118 345.472 184.199 348.078 186.122 350.001C188.044 351.923 190.65 353.004 193.368 353.007H739.724C742.442 353.004 745.048 351.923 746.971 350.001C748.893 348.078 749.974 345.472 749.977 342.754V32.2247C749.974 29.5063 748.893 26.9002 746.971 24.978C745.049 23.0558 742.442 21.9746 739.724 21.9714H193.368Z" fill="white"/>
<path id="Vector_4" d="M196.959 394.021C196.119 394.022 195.297 394.264 194.59 394.717C193.882 395.17 193.319 395.816 192.967 396.578L164.668 458.098C164.36 458.768 164.225 459.504 164.276 460.239C164.327 460.974 164.562 461.685 164.959 462.305C165.357 462.926 165.904 463.436 166.551 463.79C167.197 464.144 167.922 464.329 168.659 464.329H763.771C764.52 464.329 765.256 464.138 765.91 463.773C766.565 463.408 767.115 462.882 767.508 462.245C767.902 461.608 768.126 460.88 768.16 460.132C768.193 459.384 768.035 458.639 767.7 457.969L736.94 396.449C736.577 395.718 736.017 395.103 735.322 394.674C734.628 394.244 733.827 394.018 733.01 394.021L196.959 394.021Z" fill="#CCCCCC"/>
<path id="Vector_5" d="M464.349 16.8447C467.989 16.8447 470.94 13.8937 470.94 10.2533C470.94 6.61297 467.989 3.6619 464.349 3.6619C460.709 3.6619 457.758 6.61297 457.758 10.2533C457.758 13.8937 460.709 16.8447 464.349 16.8447Z" fill="white"/>
<path id="Vector_6" d="M405.854 476.047C404.888 476.048 403.95 476.367 403.183 476.955C402.416 477.543 401.864 478.366 401.612 479.298L394.513 505.664C394.338 506.315 394.315 506.998 394.445 507.66C394.576 508.322 394.858 508.944 395.268 509.48C395.678 510.015 396.206 510.449 396.811 510.748C397.416 511.046 398.081 511.201 398.756 511.201H533.754C534.454 511.201 535.144 511.034 535.767 510.713C536.39 510.392 536.926 509.926 537.333 509.356C537.739 508.785 538.003 508.125 538.102 507.432C538.202 506.739 538.134 506.031 537.905 505.37L528.778 479.004C528.479 478.141 527.919 477.392 527.174 476.862C526.43 476.332 525.539 476.047 524.626 476.047H405.854Z" fill="#CCCCCC"/>
<path id="Vector_7" d="M766.09 366.922V372.782H162.169L162.623 372.049V366.922H766.09Z" fill="#CCCCCC"/>
<path id="Vector_8" d="M691.547 380.344C691.312 380.344 691.083 380.422 690.897 380.565C690.71 380.708 690.576 380.909 690.514 381.136L688.786 387.556C688.743 387.715 688.737 387.881 688.769 388.042C688.801 388.203 688.87 388.355 688.969 388.485C689.069 388.616 689.198 388.721 689.345 388.794C689.492 388.867 689.654 388.905 689.819 388.905H722.693C722.863 388.905 723.031 388.864 723.183 388.786C723.335 388.707 723.465 388.594 723.564 388.455C723.663 388.316 723.727 388.156 723.752 387.987C723.776 387.818 723.759 387.646 723.704 387.484L721.481 381.064C721.408 380.854 721.272 380.671 721.091 380.542C720.909 380.413 720.692 380.344 720.47 380.344H691.547Z" fill="#CCCCCC"/>
<path id="Vector_9" d="M643.547 380.344C643.312 380.344 643.083 380.422 642.897 380.565C642.71 380.708 642.576 380.909 642.514 381.136L640.786 387.556C640.743 387.715 640.737 387.881 640.769 388.042C640.801 388.203 640.87 388.355 640.969 388.485C641.069 388.616 641.198 388.721 641.345 388.794C641.492 388.867 641.654 388.905 641.819 388.905H674.693C674.863 388.905 675.031 388.864 675.183 388.786C675.335 388.707 675.465 388.594 675.564 388.455C675.663 388.316 675.727 388.156 675.752 387.987C675.776 387.818 675.759 387.646 675.704 387.484L673.481 381.064C673.408 380.854 673.272 380.671 673.091 380.542C672.909 380.413 672.692 380.344 672.47 380.344H643.547Z" fill="#CCCCCC"/>
</g>
<path class="ladder" d="M504.481 421.14H507.058L501.905 76.7513H499.328L499.431 83.6219H441.684L441.787 76.7513H439.211L434.058 421.14H436.634L436.968 398.81H504.147L504.481 421.14ZM500.768 172.94H440.348L440.978 130.857H500.138L500.768 172.94ZM500.806 175.516L501.436 217.598H439.68L440.309 175.516H500.806ZM501.474 220.175L502.104 262.257H439.012L439.641 220.175H501.474ZM502.143 264.834L502.772 306.916H438.343L438.973 264.834H502.143ZM502.811 309.492L503.44 351.575H437.675L438.305 309.492H502.811ZM499.47 86.1984L500.099 128.281H441.016L441.646 86.1984H499.47ZM437.007 396.234L437.637 354.151H503.479L504.109 396.234H437.007Z" fill="#3F3D56"/>
<g class="alienthree">
<path id="Vector_10" d="M457.794 195.37C480.997 195.37 499.807 176.56 499.807 153.358C499.807 130.155 480.997 111.345 457.794 111.345C434.592 111.345 415.782 130.155 415.782 153.358C415.782 176.56 434.592 195.37 457.794 195.37Z" fill="#2F2E41"/>
<path id="Vector_11" d="M482.506 182.392L469.947 184.665L474.021 207.167L486.58 204.893L482.506 182.392Z" fill="#2F2E41"/>
<path id="Vector_12" d="M457.388 186.939L444.829 189.212L448.903 211.714L461.462 209.44L457.388 186.939Z" fill="#2F2E41"/>
<path id="Vector_13" d="M455.57 216.45C460.052 212.653 462.532 208.213 461.108 206.532C459.685 204.851 454.897 206.566 450.414 210.362C445.932 214.158 443.452 218.599 444.876 220.28C446.299 221.961 451.087 220.246 455.57 216.45Z" fill="#2F2E41"/>
<path id="Vector_14" d="M480.165 211.997C484.647 208.201 487.127 203.761 485.703 202.08C484.28 200.399 479.492 202.113 475.009 205.91C470.527 209.706 468.047 214.146 469.47 215.827C470.894 217.508 475.682 215.793 480.165 211.997Z" fill="#2F2E41"/>
<path id="Vector_15" d="M454.853 157.44C462.783 157.44 469.212 151.011 469.212 143.081C469.212 135.151 462.783 128.723 454.853 128.723C446.923 128.723 440.495 135.151 440.495 143.081C440.495 151.011 446.923 157.44 454.853 157.44Z" fill="white"/>
<path id="Vector_16" d="M448.952 142.721C451.595 142.721 453.738 140.578 453.738 137.934C453.738 135.291 451.595 133.148 448.952 133.148C446.308 133.148 444.166 135.291 444.166 137.934C444.166 140.578 446.308 142.721 448.952 142.721Z" fill="#3F3D56"/>
<path id="Vector_17" d="M490.936 107.653C491.568 92.0991 478.163 78.9251 460.995 78.2278C443.828 77.5304 429.399 89.5738 428.767 105.127C428.135 120.681 440.069 124.215 457.236 124.912C474.404 125.61 490.304 123.206 490.936 107.653Z" fill="#6C63FF"/>
<path id="Vector_18" d="M439.477 150.397C440.291 146.847 431.783 141.868 420.475 139.276C409.167 136.684 399.34 137.46 398.527 141.01C397.713 144.56 406.22 149.539 417.528 152.131C428.837 154.723 438.663 153.947 439.477 150.397Z" fill="#2F2E41"/>
<path id="Vector_19" d="M521.946 164.111C522.759 160.561 514.252 155.582 502.944 152.99C491.636 150.398 481.809 151.175 480.995 154.725C480.182 158.275 488.689 163.254 499.997 165.846C511.305 168.438 521.132 167.661 521.946 164.111Z" fill="#2F2E41"/>
</g>
<g class="alientwo">
<path id="Vector_20" d="M315.5 296.047C319.746 284.938 320.361 274.852 316.874 273.519C313.387 272.186 307.117 280.111 302.871 291.22C298.625 302.329 298.01 312.415 301.498 313.748C304.985 315.081 311.254 307.156 315.5 296.047Z" fill="#2F2E41"/>
<path id="Vector_21" d="M311.89 333.054C315.7 309.576 299.756 287.454 276.277 283.644C252.799 279.834 230.678 295.779 226.868 319.257C223.058 342.735 239.002 364.857 262.48 368.667C285.958 372.477 308.08 356.533 311.89 333.054Z" fill="#2F2E41"/>
<path id="Vector_22" d="M262.837 359.955H249.753V383.397H262.837V359.955Z" fill="#2F2E41"/>
<path id="Vector_23" d="M289.004 359.955H275.921V383.397H289.004V359.955Z" fill="#2F2E41"/>
<path id="Vector_24" d="M260.656 387.758C266.678 387.758 271.559 385.928 271.559 383.67C271.559 381.412 266.678 379.581 260.656 379.581C254.635 379.581 249.753 381.412 249.753 383.67C249.753 385.928 254.635 387.758 260.656 387.758Z" fill="#2F2E41"/>
<path id="Vector_25" d="M286.824 387.213C292.845 387.213 297.727 385.383 297.727 383.125C297.727 380.866 292.845 379.036 286.824 379.036C280.802 379.036 275.921 380.866 275.921 383.125C275.921 385.383 280.802 387.213 286.824 387.213Z" fill="#2F2E41"/>
<path id="Vector_26" d="M227.602 286.104C224.125 270.531 235.241 254.794 252.431 250.956C269.621 247.117 286.375 256.631 289.852 272.204C293.33 287.778 281.937 293.522 264.747 297.36C247.558 301.199 231.08 301.678 227.602 286.104Z" fill="#6C63FF"/>
<path id="Vector_27" d="M240.327 315.4C241.927 312.027 234.513 305.161 223.767 300.064C213.022 294.968 203.014 293.571 201.414 296.945C199.814 300.318 207.228 307.184 217.974 312.28C228.719 317.377 238.727 318.773 240.327 315.4Z" fill="#2F2E41"/>
</g>
<g class="alienfive">
<path id="Vector_28" d="M82.9008 412.831C106.686 412.831 125.968 393.549 125.968 369.763C125.968 345.978 106.686 326.696 82.9008 326.696C59.1154 326.696 39.8335 345.978 39.8335 369.763C39.8335 393.549 59.1154 412.831 82.9008 412.831Z" fill="#2F2E41"/>
<path id="Vector_29" d="M76.3589 403.563H63.2752V427.005H76.3589V403.563Z" fill="#2F2E41"/>
<path id="Vector_30" d="M102.526 403.563H89.4427V427.005H102.526V403.563Z" fill="#2F2E41"/>
<path id="Vector_31" d="M74.1783 431.366C80.1999 431.366 85.0814 429.535 85.0814 427.277C85.0814 425.019 80.1999 423.188 74.1783 423.188C68.1566 423.188 63.2751 425.019 63.2751 427.277C63.2751 429.535 68.1566 431.366 74.1783 431.366Z" fill="#2F2E41"/>
<path id="Vector_32" d="M100.346 430.821C106.367 430.821 111.249 428.99 111.249 426.732C111.249 424.474 106.367 422.643 100.346 422.643C94.3242 422.643 89.4427 424.474 89.4427 426.732C89.4427 428.99 94.3242 430.821 100.346 430.821Z" fill="#2F2E41"/>
<path id="Vector_33" d="M83.9911 373.579C92.1203 373.579 98.7104 366.989 98.7104 358.86C98.7104 350.731 92.1203 344.141 83.9911 344.141C75.8619 344.141 69.2719 350.731 69.2719 358.86C69.2719 366.989 75.8619 373.579 83.9911 373.579Z" fill="white"/>
<path id="Vector_34" d="M83.9911 363.767C86.7009 363.767 88.8976 361.57 88.8976 358.86C88.8976 356.15 86.7009 353.954 83.9911 353.954C81.2814 353.954 79.0847 356.15 79.0847 358.86C79.0847 361.57 81.2814 363.767 83.9911 363.767Z" fill="#3F3D56"/>
<path id="Vector_35" d="M41.124 329.712C37.6465 314.138 48.7627 298.401 65.9526 294.563C83.1426 290.725 99.8968 300.238 103.374 315.812C106.852 331.386 95.4594 337.13 78.2695 340.968C61.0795 344.806 44.6015 345.286 41.124 329.712Z" fill="#6C63FF"/>
</g>
<g class="alienfour">
<path id="Vector_36" d="M114.303 215.904C118.549 204.795 119.164 194.709 115.677 193.376C112.189 192.043 105.92 199.968 101.674 211.077C97.4282 222.186 96.8132 232.272 100.301 233.605C103.788 234.938 110.057 227.013 114.303 215.904Z" fill="#2F2E41"/>
<path id="Vector_37" d="M110.693 252.911C114.503 229.433 98.5585 207.312 75.0802 203.502C51.6019 199.692 29.4803 215.636 25.6703 239.114C21.8603 262.592 37.8046 284.714 61.2829 288.524C84.7612 292.334 106.883 276.39 110.693 252.911Z" fill="#2F2E41"/>
<path id="Vector_38" d="M61.6397 279.812H48.5559V303.254H61.6397V279.812Z" fill="#2F2E41"/>
<path id="Vector_39" d="M87.8072 279.812H74.7234V303.254H87.8072V279.812Z" fill="#2F2E41"/>
<path id="Vector_40" d="M59.4591 307.615C65.4807 307.615 70.3622 305.785 70.3622 303.527C70.3622 301.269 65.4807 299.438 59.4591 299.438C53.4374 299.438 48.5559 301.269 48.5559 303.527C48.5559 305.785 53.4374 307.615 59.4591 307.615Z" fill="#2F2E41"/>
<path id="Vector_41" d="M85.6266 307.07C91.6482 307.07 96.5297 305.24 96.5297 302.982C96.5297 300.723 91.6482 298.893 85.6266 298.893C79.6049 298.893 74.7234 300.723 74.7234 302.982C74.7234 305.24 79.6049 307.07 85.6266 307.07Z" fill="#2F2E41"/>
<path id="Vector_42" d="M69.2719 249.829C77.4011 249.829 83.9911 243.239 83.9911 235.11C83.9911 226.98 77.4011 220.39 69.2719 220.39C61.1427 220.39 54.5527 226.98 54.5527 235.11C54.5527 243.239 61.1427 249.829 69.2719 249.829Z" fill="white"/>
<path id="Vector_43" d="M69.2719 240.016C71.9816 240.016 74.1783 237.819 74.1783 235.11C74.1783 232.4 71.9816 230.203 69.2719 230.203C66.5622 230.203 64.3655 232.4 64.3655 235.11C64.3655 237.819 66.5622 240.016 69.2719 240.016Z" fill="#3F3D56"/>
<path id="Vector_44" d="M26.4048 205.961C22.9273 190.388 34.0435 174.651 51.2334 170.813C68.4234 166.974 85.1777 176.488 88.6551 192.061C92.1326 207.635 80.7402 213.379 63.5503 217.217C46.3603 221.056 29.8823 221.535 26.4048 205.961Z" fill="#6C63FF"/>
<path id="Vector_45" d="M39.1297 235.257C40.7296 231.884 33.3157 225.018 22.5703 219.921C11.8249 214.825 1.81705 213.428 0.21714 216.802C-1.38277 220.175 6.03114 227.041 16.7765 232.137C27.522 237.234 37.5298 238.63 39.1297 235.257Z" fill="#2F2E41"/>
<path id="Vector_46" d="M47.027 261.55C47.027 265.765 57.8803 274.088 69.9236 274.088C81.9668 274.088 93.2587 262.221 93.2587 258.006C93.2587 253.791 81.9668 258.824 69.9236 258.824C57.8803 258.824 47.027 257.335 47.027 261.55Z" fill="white"/>
</g>
<g class="frameone">
<path id="Vector_47" d="M879.438 193.3C879.087 193.045 878.679 192.879 878.249 192.819L626.756 156.81C626.059 156.711 625.351 156.893 624.788 157.315C624.224 157.738 623.851 158.366 623.751 159.063L597.903 339.582C597.804 340.28 597.986 340.988 598.408 341.552C598.831 342.115 599.46 342.488 600.157 342.589L851.649 378.598C852.347 378.697 853.055 378.515 853.618 378.093C854.182 377.67 854.555 377.042 854.655 376.345L880.502 195.825C880.552 195.48 880.534 195.128 880.447 194.789C880.361 194.451 880.208 194.134 879.998 193.855C879.84 193.643 879.651 193.457 879.438 193.3Z" fill="#6C63FF"/>
<path id="Vector_48" d="M862.051 206.976L843.683 335.263C843.623 335.687 843.479 336.095 843.261 336.464C843.042 336.833 842.753 337.155 842.41 337.412C842.068 337.669 841.677 337.856 841.262 337.962C840.847 338.068 840.415 338.091 839.991 338.03L622.216 306.849C622.06 306.828 621.907 306.794 621.758 306.747C621.006 306.527 620.358 306.042 619.934 305.383C619.509 304.723 619.337 303.933 619.448 303.157L637.816 174.87C637.877 174.446 638.021 174.038 638.239 173.669C638.457 173.3 638.746 172.978 639.089 172.721C639.432 172.464 639.822 172.277 640.237 172.171C640.652 172.065 641.085 172.042 641.509 172.103L859.284 203.284C859.708 203.344 860.116 203.488 860.485 203.706C860.854 203.925 861.176 204.214 861.433 204.557C861.69 204.899 861.877 205.29 861.983 205.705C862.089 206.12 862.112 206.552 862.051 206.976Z" fill="white"/>
<path id="Vector_49" d="M669.421 250.553C682.185 259.88 700.094 257.095 709.421 244.331C718.749 231.567 715.963 213.658 703.199 204.331C690.435 195.003 672.526 197.789 663.199 210.553C653.871 223.317 656.657 241.226 669.421 250.553Z" fill="#FEC601"/>
<path id="Vector_50" d="M762.664 327.484L623.659 307.581C623.485 307.558 623.313 307.52 623.146 307.465L705.193 203.002C705.696 202.35 706.363 201.844 707.126 201.534C707.888 201.224 708.72 201.123 709.534 201.239C710.349 201.356 711.119 201.687 711.764 202.198C712.409 202.709 712.907 203.383 713.206 204.149L746.386 286.887L747.976 290.846L762.664 327.484Z" fill="#AB8753"/>
<path id="Vector_51" opacity="0.2" d="M762.664 327.484L715.205 320.688L743.348 290.184L745.374 287.986L746.386 286.886L747.976 290.846L762.664 327.484Z" fill="black"/>
<path id="Vector_52" d="M840.061 338.565L719.833 321.351L747.977 290.846L750.002 288.648L786.676 248.894C789.08 246.288 793.894 246.794 795.872 249.654C796.005 249.849 796.125 250.052 796.231 250.262L840.061 338.565Z" fill="#8F7248"/>
<path id="Vector_53" d="M628.414 167.143C630.152 168.412 632.59 168.033 633.859 166.296C635.129 164.558 634.75 162.12 633.012 160.85C631.275 159.581 628.837 159.96 627.567 161.697C626.298 163.435 626.677 165.873 628.414 167.143Z" fill="#3F3D56"/>
<path id="Vector_54" d="M869.621 201.679C871.359 202.948 873.797 202.569 875.066 200.832C876.336 199.094 875.957 196.656 874.219 195.386C872.482 194.117 870.044 194.496 868.774 196.233C867.504 197.971 867.884 200.409 869.621 201.679Z" fill="#3F3D56"/>
<path id="Vector_55" d="M603.672 339.947C605.41 341.217 607.848 340.838 609.117 339.1C610.387 337.363 610.008 334.925 608.27 333.655C606.533 332.386 604.095 332.765 602.825 334.502C601.555 336.24 601.935 338.678 603.672 339.947Z" fill="#3F3D56"/>
<path id="Vector_56" d="M844.879 374.483C846.616 375.753 849.054 375.374 850.324 373.636C851.594 371.899 851.215 369.461 849.477 368.191C847.739 366.922 845.302 367.301 844.032 369.038C842.762 370.776 843.141 373.214 844.879 374.483Z" fill="#3F3D56"/>
</g>
<g class="alienone">
<path id="Vector_57" d="M700.291 392.725C703.778 391.392 703.163 381.306 698.917 370.197C694.671 359.088 688.402 351.163 684.915 352.496C681.427 353.829 682.042 363.915 686.288 375.024C690.534 386.133 696.803 394.058 700.291 392.725Z" fill="#2F2E41"/>
<path id="Vector_58" d="M730.308 456.643C753.787 452.833 769.731 430.712 765.921 407.233C762.111 383.755 739.99 367.811 716.511 371.621C693.033 375.431 677.089 397.552 680.899 421.031C684.709 444.509 706.83 460.453 730.308 456.643Z" fill="#2F2E41"/>
<path id="Vector_59" d="M743.035 447.932H729.952L732.512 476.005L743.058 473.14L743.035 447.932Z" fill="#2F2E41"/>
<path id="Vector_60" d="M714.512 448.005H701.428L703.988 476.078L714.058 473.14L714.512 448.005Z" fill="#2F2E41"/>
<path id="Vector_61" d="M703.616 477.397C709.622 476.958 714.357 474.776 714.192 472.524C714.028 470.272 709.026 468.802 703.02 469.241C697.014 469.68 692.279 471.862 692.444 474.114C692.609 476.366 697.611 477.836 703.616 477.397Z" fill="#2F2E41"/>
<path id="Vector_62" d="M722.32 417.948C730.449 417.948 737.039 411.358 737.039 403.229C737.039 395.1 730.449 388.51 722.32 388.51C714.19 388.51 707.6 395.1 707.6 403.229C707.6 411.358 714.19 417.948 722.32 417.948Z" fill="white"/>
<path id="Vector_63" d="M722.32 408.135C725.029 408.135 727.226 405.939 727.226 403.229C727.226 400.519 725.029 398.323 722.32 398.323C719.61 398.323 717.413 400.519 717.413 403.229C717.413 405.939 719.61 408.135 722.32 408.135Z" fill="#3F3D56"/>
<path id="Vector_64" d="M774.356 388.165C781.421 378.599 784.715 369.046 781.712 366.828C778.708 364.609 770.546 370.566 763.48 380.132C756.415 389.699 753.121 399.252 756.124 401.47C759.127 403.688 767.29 397.731 774.356 388.165Z" fill="#2F2E41"/>
<path id="Vector_65" d="M702.397 436.339C702.397 432.865 711.342 426.005 721.268 426.005C731.194 426.005 740.5 435.785 740.5 439.259C740.5 442.733 731.194 438.585 721.268 438.585C711.342 438.585 702.397 439.813 702.397 436.339Z" fill="white"/>
<path id="Vector_66" d="M732.309 477.328C738.314 476.889 743.049 474.707 742.885 472.455C742.72 470.203 737.718 468.733 731.712 469.172C725.707 469.611 720.972 471.793 721.136 474.045C721.301 476.297 726.303 477.767 732.309 477.328Z" fill="#2F2E41"/>
<path id="Vector_67" d="M765.187 374.081C768.664 358.507 757.548 342.77 740.358 338.932C723.168 335.093 706.414 344.607 702.936 360.181C699.459 375.754 710.851 381.498 728.041 385.337C745.231 389.175 761.709 389.654 765.187 374.081Z" fill="white"/>
</g>
<g class="frametwo">
<path id="Vector_68" d="M115.949 0C115.167 0.000783559 114.418 0.302261 113.865 0.838264C113.312 1.37427 113.001 2.10101 113 2.85903V199.141C113.001 199.899 113.312 200.626 113.865 201.162C114.418 201.698 115.167 201.999 115.949 202H398.051C398.833 201.999 399.582 201.698 400.135 201.162C400.688 200.626 400.999 199.899 401 199.141V2.85903C400.999 2.10101 400.688 1.37427 400.135 0.838264C399.582 0.302261 398.833 0.000783559 398.051 0H115.949Z" fill="#6C63FF"/>
<path id="Vector_69" d="M382.471 17.5549V157.049C382.471 157.979 382.09 158.872 381.411 159.53C380.732 160.188 379.812 160.557 378.852 160.558H134.567C134.396 160.56 134.225 160.545 134.057 160.515C133.195 160.396 132.406 159.981 131.835 159.344C131.264 158.708 130.949 157.893 130.948 157.049V17.5549C130.948 16.6244 131.329 15.732 132.008 15.074C132.687 14.416 133.607 14.0462 134.567 14.0461H378.852C379.812 14.0463 380.732 14.416 381.411 15.074C382.09 15.732 382.471 16.6244 382.471 17.5549V17.5549Z" fill="white"/>
<path id="Vector_70" d="M331.839 77.882C339.087 77.882 344.963 72.1865 344.963 65.1608C344.963 58.1351 339.087 52.4396 331.839 52.4396C324.591 52.4396 318.716 58.1351 318.716 65.1608C318.716 72.1865 324.591 77.882 331.839 77.882Z" fill="#FEC601"/>
<path id="Vector_71" d="M121.43 11.2471C123.819 11.2471 125.756 9.36934 125.756 7.05302C125.756 4.73671 123.819 2.85898 121.43 2.85898C119.04 2.85898 117.103 4.73671 117.103 7.05302C117.103 9.36934 119.04 11.2471 121.43 11.2471Z" fill="#3F3D56"/>
<path id="Vector_72" d="M391.993 11.2471C394.383 11.2471 396.32 9.36934 396.32 7.05302C396.32 4.73671 394.383 2.85898 391.993 2.85898C389.603 2.85898 387.666 4.73671 387.666 7.05302C387.666 9.36934 389.603 11.2471 391.993 11.2471Z" fill="#3F3D56"/>
<path id="Vector_73" d="M121.43 199.141C123.819 199.141 125.756 197.263 125.756 194.947C125.756 192.631 123.819 190.753 121.43 190.753C119.04 190.753 117.103 192.631 117.103 194.947C117.103 197.263 119.04 199.141 121.43 199.141Z" fill="#3F3D56"/>
<path id="Vector_74" d="M391.993 199.141C394.383 199.141 396.32 197.263 396.32 194.947C396.32 192.631 394.383 190.753 391.993 190.753C389.603 190.753 387.666 192.631 387.666 194.947C387.666 197.263 389.603 199.141 391.993 199.141Z" fill="#3F3D56"/>
<path id="Vector_75" d="M211.432 115.1C220.128 115.1 227.177 108.266 227.177 99.8371C227.177 91.4078 220.128 84.5744 211.432 84.5744C202.736 84.5744 195.687 91.4078 195.687 99.8371C195.687 108.266 202.736 115.1 211.432 115.1Z" fill="#71E892"/>
<path id="Vector_76" d="M212.95 145.809H209.725L211.195 97.538L212.95 145.809Z" fill="#3F3D56"/>
<path id="Vector_77" d="M211.479 106.687L214.942 102.044L211.432 107.836L211.053 107.193L211.479 106.687Z" fill="#3F3D56"/>
<path id="Vector_78" d="M211.1 111.376L207.638 106.733L211.148 112.525L211.527 111.881L211.1 111.376Z" fill="#3F3D56"/>
<path id="Vector_79" d="M355.866 115.1C364.562 115.1 371.612 108.266 371.612 99.8371C371.612 91.4078 364.562 84.5744 355.866 84.5744C347.171 84.5744 340.121 91.4078 340.121 99.8371C340.121 108.266 347.171 115.1 355.866 115.1Z" fill="#71E892"/>
<path id="Vector_80" d="M357.384 145.809H354.159L355.629 97.538L357.384 145.809Z" fill="#3F3D56"/>
<path id="Vector_81" d="M355.914 106.687L359.376 102.044L355.867 107.836L355.487 107.193L355.914 106.687Z" fill="#3F3D56"/>
<path id="Vector_82" d="M355.535 111.376L352.073 106.733L355.582 112.525L355.961 111.881L355.535 111.376Z" fill="#3F3D56"/>
<path id="Vector_83" d="M257.044 109.065C269.887 109.065 280.298 98.9725 280.298 86.523C280.298 74.0736 269.887 63.9814 257.044 63.9814C244.2 63.9814 233.789 74.0736 233.789 86.523C233.789 98.9725 244.2 109.065 257.044 109.065Z" fill="#71E892"/>
<path id="Vector_84" d="M259.285 154.419H254.522L256.693 83.1279L259.285 154.419Z" fill="#3F3D56"/>
<path id="Vector_85" d="M257.114 96.6392L262.227 89.7819L257.044 98.3366L256.483 97.3862L257.114 96.6392Z" fill="#3F3D56"/>
<path id="Vector_86" d="M256.553 103.565L251.44 96.7071L256.623 105.263L257.184 104.312L256.553 103.565Z" fill="#3F3D56"/>
<path id="Vector_87" d="M313.754 100.454C326.597 100.454 337.009 90.3617 337.009 77.9123C337.009 65.4629 326.597 55.3706 313.754 55.3706C300.911 55.3706 290.499 65.4629 290.499 77.9123C290.499 90.3617 300.911 100.454 313.754 100.454Z" fill="#AAFDBC"/>
<path id="Vector_88" d="M315.995 145.809H311.233L313.404 74.5172L315.995 145.809Z" fill="#3F3D56"/>
<path id="Vector_89" d="M313.825 88.0285L318.937 81.1711L313.753 89.7259L313.194 88.7755L313.825 88.0285Z" fill="#3F3D56"/>
<path id="Vector_90" d="M313.264 94.9547L308.151 88.0963L313.334 96.6521L313.894 95.7017L313.264 94.9547Z" fill="#3F3D56"/>
<path id="Vector_91" d="M166.661 100.454C179.504 100.454 189.916 90.3617 189.916 77.9123C189.916 65.4629 179.504 55.3706 166.661 55.3706C153.818 55.3706 143.407 65.4629 143.407 77.9123C143.407 90.3617 153.818 100.454 166.661 100.454Z" fill="#AAFDBC"/>
<path id="Vector_92" d="M168.903 145.809H164.139L166.311 74.5172L168.903 145.809Z" fill="#3F3D56"/>
<path id="Vector_93" d="M166.731 88.0285L171.844 81.1711L166.661 89.7259L166.1 88.7755L166.731 88.0285Z" fill="#3F3D56"/>
<path id="Vector_94" d="M166.171 94.9547L161.058 88.0963L166.241 96.6521L166.801 95.7017L166.171 94.9547Z" fill="#3F3D56"/>
<path id="Vector_95" d="M258.342 160.558H134.567C134.396 160.56 134.225 160.545 134.057 160.515C133.195 160.396 132.406 159.981 131.835 159.344C131.264 158.708 130.949 157.892 130.948 157.049V127.094C142.594 125.213 154.384 124.284 166.191 124.317C210.129 124.317 246.883 136.523 256.099 152.862C257.489 155.209 258.26 157.852 258.342 160.558V160.558Z" fill="#C8A168"/>
<path id="Vector_96" d="M382.471 150.257V157.049C382.471 157.979 382.09 158.872 381.411 159.53C380.732 160.188 379.812 160.557 378.852 160.558H241.908C246.313 157.465 251.075 154.882 256.099 152.862C272.877 145.887 296.539 141.539 322.755 141.539C345.54 141.539 366.382 144.811 382.471 150.257Z" fill="#AB8753"/>
</g>
</g>
<defs>
<clipPath id="clip0">
<rect width="878.64" height="552.215" fill="white"/>
</clipPath>
</defs>
</svg>

  );
}
